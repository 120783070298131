import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteUsers,
  getUsers,
  setCurrentPage,
  showAddModal,
} from "../../../Store/Actions/UsersAction";

export const List = ({ users, formatPhone, currentPage }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePageClick = (event) => {
    dispatch(getUsers(+event.selected + 1));
    dispatch(setCurrentPage(+event.selected + 1));
  };
  return (
    <div className="home__grid">
      <div className="home__grid-header">
        <div className="grid__th">User</div>
        <div className="grid__th">Phone number</div>
        <div className="grid__th">ID number</div>
        <div className="grid__th">Status</div>
        <div className="grid__th">User visits</div>
      </div>
      {users?.data.map((item, index) => (
        <div
          key={item?.uid}
          className="home__grid-row"
          onClick={(e) => {
            if (e.target.closest("img") == null) {
              history.push("/user-details", { userData: item });
            }
          }}
        >
          <div className="name">{item.name}</div>
          <div className="phone">{formatPhone(item.phone)}</div>
          <div className="id">#{item.id}</div>
          <div className={`status ${item?.status}`}>{item?.status}</div>
          <div className="visits">
            {item?.total_visits ? item?.total_visits : "0"}
          </div>
          <div className="actions">
            <span
              className="edit"
              onClick={() => {
                dispatch(showAddModal(item));
              }}
            >
              <img src="assets/icons/edit.svg" alt="" />
            </span>
            <span
              className="delete"
              onClick={() => {
                let confirm = window.confirm("Are you sure?");
                if (confirm) {
                  dispatch(deleteUsers(item.uid, currentPage));
                }
              }}
            >
              <img src="assets/icons/trash.svg" alt="" />
            </span>
          </div>
        </div>
      ))}

      {users?.totalPage > 1 && (
        <div className="paginate">
          <ReactPaginate
            key={currentPage}
            breakLabel="..."
            nextLabel="Next"
            pageClassName="page"
            activeClassName="active"
            onPageChange={handlePageClick}
            initialPage={+currentPage - 1}
            // pageRangeDisplayed={5}
            pageCount={users?.totalPage}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};
