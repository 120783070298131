import React, { useState } from "react";

export const Input = ({
  icon,
  type = "text",
  error,
  success,
  value,
  getValue,
  clear,
  placeholder,
  id,
  refs,
  clearNot,
  errorText = "error",
  focusInput,
  phone,
  readOnly = false,
  maxLength = "500",
}) => {
  const [eye, setEye] = useState(type);
  const [eyeIcon, setEyeIcon] = useState("/assets/icons/eye-off.svg")

  return (
    <>
      <div
        className={`input ${error ? "error" : ""} ${success ? "success" : ""} ${
          focusInput ? "focus" : ""
        } ${phone ? "phone" : ""}`}
      >
        {!error
          ? icon && <img className={`input__icon`} src={icon} alt="" />
          : !clearNot && (
              <img
                onClick={() => clear && clear(id)}
                className={`input__arrow`}
                src={`/assets/icons/dark-arrow-left.svg`}
                alt=""
              />
            )}
        <input
          id={id}
          placeholder={placeholder}
          type={eye}
          value={value}
          ref={refs}
          onChange={(e) => getValue && getValue(e.target)}
          readOnly={readOnly}
          disabled={readOnly}
          maxLength={maxLength}
        />
        {type != "password" && error && (
          <img
            className={"input__error-img"}
            src={`/assets/icons/input_error.svg`}
            alt="error"
          />
        )}
        {type != "password" && success && (
          <img
            className={"input__error-img"}
            src={`/assets/icons/arrow_success.svg`}
            alt="error"
          />
        )}
        {type == "password" && (
          <img
            className="password-eye"
            onClick={() => {
              if (eye == "text") {
                setEye("password");
                setEyeIcon("/assets/icons/eye-off.svg")
              } else {
                setEye("text");
                setEyeIcon('/assets/icons/eye-on.svg')
              }
            }}
            src={eyeIcon}
            alt=""
          />
        )}
      </div>
      {error && (
        <label className="label-error" htmlFor="">
          {errorText}
        </label>
      )}
    </>
  );
};
