import React from "react";

export const Button = ({
  text,
  type,
  action,
  icon,
  disabled = false,
  loader = false,
}) => {
  return (
    <button
      onClick={() => action && action()}
      className={`button ${type}`}
      disabled={disabled}

    >
      {loader && (
        <img
          className={"button__loader"}
          src="/assets/icons/loader.svg"
          alt="loader"
        />
      )}
      {icon && <img className={"button__icon"} src={icon} alt="icon" />}
      {text}
    </button>
  );
};
