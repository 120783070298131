import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({path, exact = false, component: Component, ...props}) {
    // const {loginData} = useSelector(LoginData);
    const token = localStorage.getItem('userToken')
    if(!token) return <Redirect to="/login" />
    return (
        <Route path={path}>
            <Component {...props} />
        </Route>
    )
}
