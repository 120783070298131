const initialState = {
    data: []
};

export const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_FAQ_SUCCESS":
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
