import React, {useEffect, useState} from "react";

export const Checkbox = ({ id, checked, getValue, users, setChosenUsers, chosenUsers}) => {
  const [initialChecked, setInitialChecked] = useState(false)

  useEffect(() => {
    if (chosenUsers?.filter(el => el === id)?.length > 0) {
      setInitialChecked(true)
    } else {
      setInitialChecked(false)
    }
  }, [chosenUsers])

  const addUser = (newId) => {
    setChosenUsers((chosenUsers) => [...chosenUsers, newId]);
    setInitialChecked(true)
  };

  const removeUser = (newId) => {
    setChosenUsers((chosenUsers) => chosenUsers.filter((el) => el !== newId));
    setInitialChecked(false)
  };

  return (
    <div className="checkbox">
      <input
        id={id}
        type="checkbox"
        onChange={(e) => {
          getValue && getValue(e.target.id);
          if (e.target.checked) {
            addUser(e.target.id);
          } else {
            removeUser(e.target.id);
          }
        }}
        checked={!checked && initialChecked}
      />
      <label htmlFor={id}></label>
    </div>
  );
};
