import { HIDE_TOAST, SHOW_TOAST } from "../Types/ToastTypes";

const initialState = {
  show: false,
  title: "",
  text: "",
  type: "",
  settings: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  },
};

export const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        show: true,
        title: action.payload.title,
        text: action.payload.text,
        type: action.payload.type,
      };
    case HIDE_TOAST:
      return {
        ...state,
        show: false,
        title: "",
        text: "",
        type: "",
        settings: {
          ...state.settings,
          position: action.payload?.settings.position ?? "top-right",
        },
      };
    default:
      return state;
  }
};
