import {
  GET_CODE,
  GET_EMAIL,
  GET_NAMES,
  GET_PASSWORD,
} from "../Types/SignUpTypes";

import { loaderHide, loaderShow } from "./LoaderActions";
import { showToast } from "./ToastActions";
import Api from "../../API/api";
import {
  REGISTER_PASSWORD,
  SIGN_UP_EMAIL,
  SIGN_UP_VERIFY,
  CHANGE_COMPANY,
} from "../../API/endpoints";

export const getEmail = (email, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${SIGN_UP_EMAIL}`, { email })
    .then((res) => {
      dispatch(loaderHide());
      dispatch(emailSuccess(res.data.verify_token));
      localStorage.setItem("register_data_verify_token", res.data.verify_token);
      dispatch(
        showToast({
          title: "Success",
          text: "Please verify your email",
          type: "success",
        })
      );
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message ?? "Email is not correct",
          type: "error",
        })
      );
    });
};

export const emailSuccess = (email) => ({
  type: GET_EMAIL,
  payload: email,
});

export const getCode = (data, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${SIGN_UP_VERIFY}`, data)
    .then((res) => {
      dispatch(loaderHide());
      localStorage.setItem("register_data_member_uid", res.data.member_uid);
      localStorage.setItem(
        "register_data_password_token",
        res.data.password_token
      );
      dispatch(
        codeSuccess({
          member_uid: res.data.member_uid,
          password_token: res.data.password_token,
        })
      );
      callback(res.status);
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message ?? "Code is not correct",
          type: "error",
        })
      );
    });
};

export const codeSuccess = (code) => ({
  type: GET_CODE,
  payload: code,
});

export const getPassword = (data, callback) => (dispatch) => {
  dispatch(loaderShow());
  // setTimeout(() => {
  //   dispatch(passwordSuccess(password));
  //   callback("200");
  //   dispatch(loaderHide());
  //   localStorage.setItem("register_data_password", password);
  // }, 500);
  Api.postData(`${REGISTER_PASSWORD}`, data)
    .then((res) => {
      dispatch(loaderHide());
      dispatch(passwordSuccess(data.password));
      localStorage.setItem("userToken", res.data.token);
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const passwordSuccess = (password) => ({
  type: GET_PASSWORD,
  payload: password,
});

export const getNames = (data, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${CHANGE_COMPANY}`, data, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      localStorage.removeItem("register_data_verify_token");
      localStorage.removeItem("register_data_member_uid");
      localStorage.removeItem("register_data_password");
      localStorage.removeItem("register_data_password_token");
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const namesSuccess = (names) => ({
  type: GET_NAMES,
  payload: names,
});
