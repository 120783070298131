import {
  ADD_TOILET,
  GET_TOILETS,
  HIDE_MODAL,
  REMOVE_TOILET,
  SHOW_MODAL,
} from "../Types/ToiletTypes";

const initialState = {
  data: [],
  showModal: false,
};

export const toiletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOILETS:
      return {
        ...state,
        data: action.payload,
      };
    case REMOVE_TOILET:
      return {
        ...state,
        data: state.data.filter((item) => item.id != action.payload),
      };
    case ADD_TOILET:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};
