import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";

import { Input } from "../../../UI/Input";
import { Button } from "../../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../Store/Actions/SignInActions";
import { showToast } from "../../../Store/Actions/ToastActions";
import { changePassword } from "../../../Store/Actions/SettingsActions";
import { validationPassword } from "../../../Global/Validation";
import { ERRORS } from "../../../Const/const";

export const Security = () => {
  const dispatch = useDispatch();
  const passwordRef = useRef(null);

  const [data, setData] = useState({
    old_password: {
      value: "",
      error: false,
      text: ERRORS.old_password,
      success: false,
    },
    new_password: {
      value: "",
      error: false,
      text: ERRORS.password,
      success: false,
    },
    repeat_password: {
      value: "",
      error: false,
      text: ERRORS.repeat_password,
      success: false,
    },
  });

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  useEffect(() => {
    if (validationPassword(data.old_password.value)) {
      setData((old) => ({
        ...old,
        old_password: { ...old.old_password, error: false, success: true },
      }));
    }
  }, [data.old_password.value]);

  useEffect(() => {
    if (validationPassword(data.new_password.value)) {
      setData((old) => ({
        ...old,
        new_password: { ...old.new_password, error: false, success: true },
      }));
    }
  }, [data.new_password.value]);

  useEffect(() => {
    if (data.repeat_password.value?.length >= 8) {
      setData((old) => ({
        ...old,
        repeat_password: {
          ...old.repeat_password,
          error: false,
          success: true,
        },
      }));
    }
  }, [data.repeat_password.value]);

  const getValue = (target) => {
    setData((old) => ({
      ...old,
      [target.id]: { ...old[target.id], value: target.value },
    }));
  };

  const clearValue = (target) => {
    setData((old) => ({
      ...old,
      [target]: { ...old[target], value: "", error: false, success: false },
    }));
  };

  const localValidateOldPassword = () => {
    if (validationPassword(data.old_password.value)) {
      setData((old) => ({
        ...old,
        old_password: { ...old.old_password, error: false, success: true },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        old_password: {
          ...old.old_password,
          error: true,
          success: false,
          text: ERRORS.old_password,
        },
      }));
      return false;
    }
  };

  const localValidatePassword = () => {
    if (validationPassword(data.new_password.value)) {
      setData((old) => ({
        ...old,
        new_password: { ...old.new_password, error: false, success: true },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        new_password: { ...old.new_password, error: true, success: false },
      }));
      return false;
    }
  };

  const validationRepeatPassword = () => {
    if (
      data.new_password.value == data.repeat_password.value &&
      data.repeat_password.value?.length > 0
    ) {
      setData((old) => ({
        ...old,
        repeat_password: {
          ...old.repeat_password,
          error: false,
          success: true,
        },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        repeat_password: {
          ...old.repeat_password,
          error: true,
          success: false,
        },
      }));
      return false;
    }
  };

  const validPass = () => {
    setData((old) => ({
      ...old,
      old_password: {
        ...old.old_password,
        error: true,
        text: "The old password is incorrect",
        success: false,
      },
    }));
  };

  const passwordHandler = () => {
    const oldPasswordValid = localValidateOldPassword();
    const passwordValid = localValidatePassword();
    const repeatValid = validationRepeatPassword();

    passwordValid &&
      repeatValid &&
      oldPasswordValid &&
      dispatch(
        changePassword(
          {
            old_password: data.old_password.value,
            password: data.new_password.value,
            password_confirmation: data.repeat_password.value,
          },
          validPass
        )
      );
    // getPassword(
    //   {
    //     member_uid: localStorage.getItem("forgot_data_member_uid"),
    //     password_token: localStorage.getItem("forgot_data_password_token"),
    //     password: data.password.value.trim(),
    //   },
    //   passwordAction
    // )
  };

  return (
    <section className={"security"}>
      <Helmet>
        <title>Security | Wazoo</title>
      </Helmet>
      <h2 className="settings__subtitle">Login</h2>
      <form
        action="#"
        className="security__form"
        onSubmit={(e) => {
          e.preventDefault();
          passwordHandler();
        }}
      >
        <div className="security__field">
          <div className="label">
            <span>Old Password</span>
            <span data-tip data-for="oldPass">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                  fill="#9A9FA5"
                />
              </svg>
            </span>
            <ReactTooltip id="oldPass" effect="solid">
              <span>
                Please provide current password
              </span>
            </ReactTooltip>
          </div>
          <div className="security__input">
            <Input
              id="old_password"
              icon="/assets/icons/password.svg"
              type="password"
              placeholder="Enter password"
              value={data.old_password.value}
              error={data.old_password.error}
              success={data.old_password.success}
              errorText={data.old_password.text}
              getValue={getValue}
              refs={passwordRef}
              clear={clearValue}
            />
          </div>
        </div>
        <div className="security__row">
          <div className="security__field">
            <div className="label">
              <span>New password</span>
              <span data-tip data-for="newPass">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                    fill="#9A9FA5"
                  />
                </svg>
              </span>
              <ReactTooltip id="newPass" effect="solid">
                <span>
                  Must contain upper-case, lower-case and special character
                </span>
              </ReactTooltip>
            </div>
            <div className="security__input">
              <Input
                id="new_password"
                icon="/assets/icons/password.svg"
                type="password"
                placeholder="Enter new"
                value={data.new_password.value}
                errorText={data.new_password.text}
                error={data.new_password.error}
                success={data.new_password.success}
                getValue={getValue}
                clear={clearValue}
              />
            </div>
          </div>
          <div className="security__field">
            <div className="label">
              <span>Confirm new password</span>
              <span data-tip data-for="confirm">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                    fill="#9A9FA5"
                  />
                </svg>
              </span>
              <ReactTooltip id="confirm" effect="solid">
                <span>
                 Please repeat your new password
                </span>
              </ReactTooltip>
            </div>
            <div className="security__input">
              <Input
                id="repeat_password"
                error={data.repeat_password.error}
                success={data.repeat_password.success}
                errorText={data.repeat_password.text}
                icon="/assets/icons/password.svg"
                type="password"
                placeholder="Repeat the new one"
                value={data.repeat_password.value}
                getValue={getValue}
                clear={clearValue}
              />
            </div>
          </div>
        </div>
        <div className="security__button">
          <Button text="Update password" type="success" />
        </div>
      </form>
    </section>
  );
};
