import React from "react";

export const Loader = () => {
  return (
    <div className="loader">
      <svg width="42" height="42" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 2C13.0523 2 13.5 2.44772 13.5 3V5C13.5 5.55228 13.0523 6 12.5 6C11.9477 6 11.5 5.55228 11.5 5V3C11.5 2.44772 11.9477 2 12.5 2Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 18C13.0523 18 13.5 18.4477 13.5 19V21C13.5 21.5523 13.0523 22 12.5 22C11.9477 22 11.5 21.5523 11.5 21V19C11.5 18.4477 11.9477 18 12.5 18Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.42903 4.92888C5.81955 4.53836 6.45272 4.53836 6.84324 4.92888L8.25746 6.34309C8.64798 6.73362 8.64798 7.36678 8.25746 7.75731C7.86693 8.14783 7.23377 8.14783 6.84324 7.75731L5.42903 6.34309C5.03851 5.95257 5.03851 5.3194 5.42903 4.92888Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7428 16.2426C17.1333 15.8521 17.7664 15.8521 18.157 16.2426L19.5712 17.6568C19.9617 18.0473 19.9617 18.6805 19.5712 19.071C19.1807 19.4616 18.5475 19.4616 18.157 19.071L16.7428 17.6568C16.3522 17.2663 16.3522 16.6331 16.7428 16.2426Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12C2.5 11.4477 2.94772 11 3.5 11L5.5 11C6.05228 11 6.5 11.4477 6.5 12C6.5 12.5523 6.05228 13 5.5 13L3.5 13C2.94772 13 2.5 12.5523 2.5 12Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5 12C18.5 11.4477 18.9477 11 19.5 11L21.5 11C22.0523 11 22.5 11.4477 22.5 12C22.5 12.5523 22.0523 13 21.5 13L19.5 13C18.9477 13 18.5 12.5523 18.5 12Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.42888 19.071C5.03836 18.6804 5.03836 18.0473 5.42888 17.6568L6.84309 16.2425C7.23362 15.852 7.86678 15.852 8.25731 16.2425C8.64783 16.6331 8.64783 17.2662 8.25731 17.6568L6.84309 19.071C6.45257 19.4615 5.8194 19.4615 5.42888 19.071Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7426 7.75725C16.3521 7.36672 16.3521 6.73356 16.7426 6.34304L18.1568 4.92882C18.5473 4.5383 19.1805 4.5383 19.571 4.92882C19.9616 5.31935 19.9616 5.95251 19.571 6.34304L18.1568 7.75725C17.7663 8.14777 17.1331 8.14777 16.7426 7.75725Z" fill="white"/>
      </svg>
    </div>
  );
};
