import { loaderHide, loaderShow } from "./LoaderActions";
import Api from "../../API/api";

import { showToast } from "./ToastActions";

import {
  GET_USERS,
  HIDE_MODAL,
  SET_CURRENT_PAGE,
  SHOW_MODAL,
} from "../Types/UsersTypes";
import {
  ADD_USER,
  USERS_GET,
  UPDATE_USER,
  DELETE_USER,
} from "../../API/endpoints";

export const getUsers =
  (page = "1", perPage = "10") =>
  (dispatch) => {
    dispatch(loaderShow());
    Api.getData(
      `${USERS_GET}?perPage=${perPage}&page=${page}`,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        res.data.list.length !== 0 && dispatch(getUsersSuccess(res.data));
        if (res.data.list.length === 0) {
          dispatch(loaderShow());
          Api.getData(
            `${USERS_GET}?perPage=${perPage}&page=${+page - 1}`,
            localStorage.getItem("userToken")
          )
            .then((res) => {
              dispatch(loaderHide());
              dispatch(getUsersSuccess(res.data));
              dispatch(setCurrentPage(+page - 1));
            })
            .catch((err) => {
              dispatch(loaderHide());
            });
        }
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };
export const addUser = (data) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${ADD_USER}`, data, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(getUsers());
      dispatch(hideAddModal());
      dispatch(setCurrentPage(1));
      dispatch(
        showToast({
          title: "Success",
          text: res.data.message,
          type: "success",
        })
      );
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
};

export const updateUser = (data) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${UPDATE_USER}`, data, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(getUsers());
      dispatch(hideAddModal());
      dispatch(loaderHide());
      dispatch(setCurrentPage(1));
      dispatch(
        showToast({
          title: "Success",
          text: res.data.message,
          type: "success",
        })
      );
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
};

export const deleteUsers =
  (uid, currectPage = "1") =>
  (dispatch) => {
    dispatch(loaderShow());
    Api.postData(`${DELETE_USER}`, { uid }, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        dispatch(getUsers(currectPage));
        dispatch(
          showToast({
            title: "Success",
            text: res.data.message,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Error",
            text: err.response.data.message,
            type: "error",
          })
        );
      });
  };

export const getUsersSuccess = (data) => ({
  type: GET_USERS,
  payload: data,
});

export const showAddModal = (data) => ({
  type: SHOW_MODAL,
  payload: data,
});

export const hideAddModal = () => ({
  type: HIDE_MODAL,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
