import { HIDE_TOAST, SHOW_TOAST } from "../Types/ToastTypes";

export const showToast = (payload) => {
  return {
    type: SHOW_TOAST,
    payload,
  };
};

export const hideToast = (payload) => {
  return {
    type: HIDE_TOAST,
    payload,
  };
};
