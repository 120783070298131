import { LOADER_HIDE, LOADER_SHOW } from "../Types/LoaderTypes";

export const loaderShow = () => {
  return {
    type: LOADER_SHOW,
  };
};

export const loaderHide = () => {
  return {
    type: LOADER_HIDE,
  };
};
