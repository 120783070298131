import { GET_USER } from "../Types/StaffTypes";
import { HIDE_TOAST, SHOW_TOAST } from "../Types/ToastTypes";

const initialState = {
  user: null,
};
export const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
