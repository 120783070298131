import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Checkbox } from "../../UI/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUsers,
  getUsers,
  hideAddModal,
  setCurrentPage,
  showAddModal,
  updateUser,
} from "../../Store/Actions/UsersAction";
import { getUserData } from "../../Store/Actions/SignInActions";
import { exportStaff, importStaff } from "../../Store/Actions/StaffActions";
import { HomeAnalytics } from "../../Components/Home/Analytics";
import { Button } from "../../UI/Button";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { List } from "../../Components/Home/List";

export const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useSelector((state) => state.usersReducer);
  const [modalShow, setModalShow] = useState(false);
  const currentPage = useSelector((state) => state.usersReducer).currentPage;
  const handleEscape = (e) => {
    if (e.key == "Escape") {
      dispatch(hideAddModal());
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const getExportLink = () => {
    dispatch(exportStaff());
  };

  const importFile = (e) => {
    dispatch(importStaff(e.target.files[0]));
    setModalShow(false);
  };

  const handlePageClick = (event) => {
    dispatch(getUsers(+event.selected + 1));
    dispatch(setCurrentPage(+event.selected + 1));
    // setCurrentPage(+event.selected + 1);
  };

  const formatPhone = (value) => {
    let formattedValue = value.replace(
      /(\+\d{1})(\d{3})(\d{3})(\d{4})/gi,
      "$1 ($2) $3-$4"
    );
    return formattedValue;
  };

  const companyStats = useSelector(
    (state) => state.signInReducer.data?.company_stats
  );

  return (
    <div className="home">
      <Helmet>
        <title>Home | Wazoo</title>
      </Helmet>
      {modalShow && (
        <div className="modal">
          <div className="modal__content">
            <h5 className="title">Import File</h5>
            <div className="description">
              Please upload CSV file, in format: <b>Name, Phone Number</b>{" "}
              without header row
            </div>
            {/* <img
              src="/assets/images/import-example.png"
              alt=""
              className="img"
            /> */}
            <span>Example:</span>
            <div className="example">
              John Smith, 1234567890 <br />
              Jane Doe, 1234567899 <br />
            </div>
            <label htmlFor="import" className="button">
              <span className="export">Import</span>
              <input
                onChange={(e) => importFile(e)}
                id="import"
                className="home__import"
                type="file"
                accept=".csv"
              />
            </label>
          </div>
          <div onClick={() => setModalShow(false)} className="modal__bg"></div>
        </div>
      )}
      <div className="main__header">
        <h1 className="title">Dashboard</h1>
        <div className="main__header-buttons">
          <button
            disabled={users?.data.length > 0 ? false : true}
            onClick={getExportLink}
            className="button"
          >
            <img src="assets/icons/export.svg" alt="" />
            <span className="export">Export</span>
          </button>
          <label
            onClick={() => {
              setModalShow(!modalShow);
            }}
            className="button"
          >
            <img src="assets/icons/import.svg" alt="" />
            <span className="export">Import</span>
          </label>
          <button
            onClick={() => {
              dispatch(showAddModal());
            }}
            className="button add-user"
          >
            <img src="assets/icons/add-user.svg" alt="" />
            <span className="export">Add user</span>
          </button>
        </div>
      </div>
      <div className="home__content">
        <div className="white-box">
          <h2 className="home__subtitle">Overview</h2>
          <HomeAnalytics />
        </div>
        <div className="white-box">
          <h2 className="home__subtitle">Staff</h2>
          <>
            {users?.data?.length > 0 ? (
              <List
                users={users}
                currentPage={currentPage}
                formatPhone={formatPhone}
              />
            ) : (
              // <div className="home__table">
              //   <div className="table">
              //     <div className="table__tr">
              //       {/*<div className="table__header">*/}
              //       {/* <div className="table__th table__checkbox">
              //       <div className="checkbox">
              //         <input
              //           id="all"
              //           type="checkbox"
              //           onChange={(e) => {
              //             if (e.target.checked) {
              //               setChosenUsers(users.map(item => item.uid))
              //             } else {
              //               setChosenUsers([])
              //             }
              //           }}
              //           checked={users?.length == 0 ? false : chosenUsers?.length === users?.length}
              //         />
              //         <label htmlFor='all'></label>
              //       </div>
              //     </div> */}
              //       {/*</div>*/}
              //       <div className="table__th user">User</div>
              //       <div className="table__th phone">Phone number</div>
              //       <div className="table__th id">ID number</div>
              //       <div className="table__th status">Status</div>
              //       <div className="table__th visit">User visits</div>
              //     </div>
              //     {users?.data?.map((item) => (
              //       <div
              //         onClick={(e) => {
              //           if (e.target.closest("img") == null) {
              //             history.push("/user-details", { userData: item });
              //           }
              //         }}
              //         className="table__tr user-row"
              //         key={item?.uid}
              //       >
              //         <div className="table__header">
              //           {/* <div className="table__td table__checkbox">
              //             <Checkbox
              //               id={item.uid}
              //               chosenUsers={chosenUsers}
              //               setChosenUsers={setChosenUsers}
              //             />
              //           </div> */}
              //           <div className="table__td user">{item.name}</div>
              //         </div>
              //         <div className="table__body">
              //           <div className="table__column phone">
              //             <span className="table__td subscription">
              //               Phone number:
              //             </span>
              //             <div className="table__td phone">
              //               {formatPhone(item.phone)}
              //             </div>
              //           </div>
              //           <div className="table__body-hr"></div>
              //           <div className="table__column id">
              //             <span className="table__td subscription">
              //               ID number:
              //             </span>
              //             <div className="table__td id">#{item.id}</div>
              //           </div>
              //           <div className="table__body-hr"></div>
              //           <div className="table__visit">
              //             <span className="table__td subscription">
              //               Status:
              //             </span>
              //             <div className={`table__td visit ${item?.status}`}>
              //               {item?.status}
              //             </div>
              //           </div>
              //           <div className="table__body-hr"></div>
              //           <div className="table__column visits">
              //             <span className="table__td subscription">
              //               Total visits:
              //             </span>
              //             <div className="table__td visits">
              //               {item?.total_visits ? item?.total_visits : "0"}
              //             </div>
              //           </div>
              //         </div>
              //         <div className="table__btn">
              //           <div
              //             className="edit"
              //             id="edit"
              //             onClick={() => {
              //               dispatch(showAddModal(item));
              //             }}
              //           >
              //             <img src="assets/icons/edit.svg" alt="" />
              //           </div>
              //           <div
              //             className="delete"
              //             id="delete"
              //             onClick={() => {
              //               let confirm = window.confirm("Are you sure?");
              //               if (confirm) {
              //                 dispatch(deleteUsers(item.uid, currentPage));
              //               }
              //             }}
              //           >
              //             <img src="assets/icons/trash.svg" alt="" />
              //           </div>
              //         </div>
              //         {/*<div className="table__hr"></div>*/}
              //       </div>
              //     ))}
              //     {users?.totalPage > 1 && (
              //       <div className="paginate">
              //         <ReactPaginate
              //           key={currentPage}
              //           breakLabel="..."
              //           nextLabel="Next"
              //           pageClassName="page"
              //           activeClassName="active"
              //           onPageChange={handlePageClick}
              //           initialPage={+currentPage - 1}
              //           // pageRangeDisplayed={5}
              //           pageCount={users?.totalPage}
              //           previousLabel="Prev"
              //           renderOnZeroPageCount={null}
              //         />
              //       </div>
              //     )}
              //   </div>
              // </div>
              <div className="home__list-none">
                <img src="/assets/icons/no-list.svg" alt="" />
                <p>You have not added users yet</p>
                <Button
                  text="Add users"
                  type="success"
                  action={() => dispatch(showAddModal())}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
