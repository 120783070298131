import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getPassword } from "../../../Store/Actions/ForgotActions";

import { Button } from "../../../UI/Button";
import { Input } from "../../../UI/Input";
import { validationPassword } from "../../../Global/Validation";
import { ERRORS } from "../../../Const/const";

export const ForgotPasswordCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const passwordRef = useRef(null);
  const repeatRef = useRef(null);
  const [data, setData] = useState({
    password: {
      value: "",
      error: false,
      text: ERRORS.password,
      success: false,
    },
    repeat: {
      value: "",
      error: false,
      text: ERRORS.repeat_password,
      success: false,
    },
  });

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  useEffect(() => {
    if (validationPassword(data.password.value)) {
      setData((old) => ({
        ...old,
        password: { ...old.password, error: false, success: true },
      }));
    }
  }, [data.password.value]);

  useEffect(() => {
    if (data.repeat.value?.length >= 8) {
      setData((old) => ({
        ...old,
        repeat: { ...old.repeat, error: false, success: true },
      }));
    }
  }, [data.repeat.value]);

  const passwordAction = () => {
    history.push("/login");
  };

  const getValue = (target) => {
    setData((old) => ({
      ...old,
      [target.id]: { ...old[target.id], value: target.value },
    }));
  };

  const clearValue = (target) => {
    setData((old) => ({
      ...old,
      [target]: { ...old[target], value: "", error: false, success: false },
    }));
  };

  const localValidatePassword = () => {
    if (validationPassword(data.password.value)) {
      setData((old) => ({
        ...old,
        password: { ...old.password, error: false, success: true },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        password: { ...old.password, error: true, success: false },
      }));
      return false;
    }
  };
  const validationRepeatPassword = () => {
    if (
      data.password.value == data.repeat.value &&
      data.repeat.value?.length > 0
    ) {
      setData((old) => ({
        ...old,
        repeat: { ...old.repeat, error: false, success: true },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        repeat: { ...old.repeat, error: true, success: false },
      }));
      return false;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const passwordValid = localValidatePassword();
    const repeatValid = validationRepeatPassword();

    passwordValid &&
      repeatValid &&
      dispatch(
        getPassword(
          {
            member_uid: localStorage.getItem("forgot_data_member_uid"),
            password_token: localStorage.getItem("forgot_data_password_token"),
            password: data.password.value.trim(),
          },
          passwordAction
        )
      );
  };

  return (
    <section className="forgot__password">
      <h1 className={"forgot__title"}>Create a password</h1>
      <form
        action="#"
        onSubmit={submitHandler}
        className="forgot__password-form"
      >
        <div className="forgot__password-input">
          <Input
            type="password"
            error={data.password.error}
            success={data.password.success}
            errorText={data.password.text}
            icon={"/assets/icons/password.svg"}
            value={data.password.value}
            id="password"
            refs={passwordRef}
            placeholder="Enter password"
            getValue={getValue}
            clear={clearValue}
          />
        </div>
        <div className="forgot__password-input">
          <Input
            type="password"
            error={data.repeat.error}
            success={data.repeat.success}
            errorText={data.repeat.text}
            icon={"/assets/icons/password.svg"}
            value={data.repeat.value}
            id="repeat"
            placeholder="Repeat password"
            getValue={getValue}
            clear={clearValue}
            refs={repeatRef}
          />
        </div>
        {/*<p className="forgot__password-subtitle">Your password must have:</p>*/}
        {/*<p className="forgot__password-info">*/}
        {/*  <span>*/}
        {/*    <img src="/assets/icons/info-gray.svg" alt="" />*/}
        {/*  </span>*/}
        {/*  <span>8 to 20 characters</span>*/}
        {/*</p>*/}
        {/*<p className="forgot__password-info">*/}
        {/*  <span>*/}
        {/*    <img src="/assets/icons/info-gray.svg" />*/}
        {/*  </span>*/}
        {/*  <span>Letters, numbers, and special charecters</span>*/}
        {/*</p>*/}
        <div className="forgot__password-button">
          <Button type="success" text="Continue" />
        </div>
      </form>
    </section>
  );
};
