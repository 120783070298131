import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { SELECT_TIME_DATA } from "../../Const/const";

import { selectStyle } from "../../Const/selectStyle";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Store/Actions/StaffActions";

export const UserDetails = () => {
  const historyUser = useSelector((state) => state.staffReducer.user);
  const dispatch = useDispatch();
  const pathState = useLocation().state?.userData;

  useEffect(() => {
    dispatch(getUser(pathState?.id));
  }, []);

  const formatPhone = (value) => {
    let formattedValue = value.replace(
      /(\+\d{1})(\d{3})(\d{3})(\d{4})/gi,
      "$1 ($2) $3-$4"
    );
    return formattedValue;
  };
  return (
    <section className="user-details">
      <Helmet>
        <title>User details | Wazoo</title>
      </Helmet>
      <h1 className="user-details__title">User details</h1>
      <div className="user-details__header">
        <div className="user-details__data">
          <div className="user-details__subtitle purple">Details</div>
          <div className="user-details__avatar">
            <img src="./assets/icons/avatar-online.svg" alt="avatar" />
          </div>
          <div className="user-details__fullname">{pathState?.name}</div>
          <div className="user-details__email">{pathState?.email}</div>
          <div className="user-details__id">ID #{pathState?.id}</div>
        </div>
        <div className="user-details__info">
          <div className="user-details__subtitle blue">Info</div>
          <div className="user-details__boxs">
            <div className="user-details__box">
              <div className="box__img">
                <img src="./assets/icons/contact.svg" alt="contact" />
              </div>
              <div className="texts">
                <div className="box__title">Contact</div>
                <div className="box__text">{formatPhone(pathState?.phone)}</div>
              </div>
            </div>
            <div className="user-details__box">
              <div className="box__img">
                <img src="./assets/icons/spent.svg" alt="spent" />
              </div>
              <div className="texts">
                <div className="box__title">Total Spent</div>
                <div className="box__text">${pathState?.total_spent}</div>
              </div>
            </div>
            <div className="user-details__box">
              <div className="box__img">
                <img src="./assets/icons/orange-calendar.svg" alt="calendar" />
              </div>
              <div className="texts">
                <div className="box__title">Last Visit</div>
                <div className="box__text">
                  {pathState?.last_visit_at
                    ? pathState?.last_visit_at
                    : "User is not active"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-details__table">
        <div className="table__header">
          <div className="table__title">User history</div>
          {/*<div className="table__select">*/}
          {/*  <Select*/}
          {/*    // defaultValue={SELECT_TIME_DATA[0]}*/}
          {/*    options={SELECT_TIME_DATA}*/}
          {/*    styles={selectStyle}*/}
          {/*    isSearchable={false}*/}
          {/*    isClearable={true}*/}
          {/*    placeholder={"Choose time"}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="table__tr th">
          <div className="table__subtitle address">Address</div>
          <div className="table__subtitle date">Date</div>
          <div className="table__subtitle time">Time</div>
        </div>
        {historyUser?.map((item, index) => (
          <div className="table__tr">
            <div className="table__value address">{item?.label}</div>
            <div className="table__value date">{item?.date}</div>
            <div className="table__value time">{item?.time}</div>
          </div>
        ))}
      </div>
    </section>
  );
};
