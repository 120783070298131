import { GET_USER_DATA, LOGIN } from "../Types/SignInType";

const initialState = {
  userToken: null,
  data: null,
};

export const signInReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userToken: action.payload,
      };
    case GET_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
