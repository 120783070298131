import React, { useState } from "react";
import "./index.scss";
import { Button } from "../../../UI/Button";
import { useHistory } from "react-router-dom";

export const SubscriptionSignUp = () => {
  const history = useHistory();

  const SubscriptionAction = () => {
    history.push("/signup/card");
  };

  return (
    <section className="signup__subscription">
      <h1 className="signup__title">Pricing</h1>
      <p className="signup__description">Subscription</p>
      <div className="">
        <div className="signup__subscription-subtitle">
          <div className="price">$ <span>9.99</span></div>
          <div className="text">user / month</div>
        </div>
        <div className="signup__subscription-hint">
          Charged only if a user starts using the app.<br/>First charge is pro rated.
        </div>
        <div className="signup__description">
          + Pay as you go
        </div>
        <div className="signup__subscription-subtitle">
          <div className="price">$ <span>0.10</span></div>
          <div className="text">each toilet use</div>
        </div>
        <div className="signup__subscription-hint">
            You will be charged at the end of each week.
        </div>
      </div>
      <div className="signup__subscription-btn">
        <Button action={SubscriptionAction} text={`I agree`} type={"success"} />
      </div>
    </section>
  );
};
