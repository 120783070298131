import axios from "axios";
import { BASE_URL } from "./endpoints";

const http = axios.create({
  baseURL: BASE_URL,
});

http.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    if (error?.response?.status == "401") {
      localStorage.removeItem("userToken");
    }
    return Promise.reject(error);
  }
);

const setHeaders = (token, image) => {
  if (!token) return;
  if (image) {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "multipart/form-data",
      },
    };
  } else {
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "timezone-utc-difference": new Date().getTimezoneOffset() * -1,
      },
    };
  }
};

const Api = {
  getData: (url, token) => http.get("/api/customer/" + url, setHeaders(token)),
  postData: (url, body, token, image) =>
    http.post("/api/customer/" + url, body, setHeaders(token, image)),
  putData: (url, content, token) =>
    http.put("/api/customer/" + url, content, setHeaders(token)),
  delete: (url, body, token) =>
    http.delete("/api/customer/" + url, { data: body, ...setHeaders(token) }),
  patchData: (url, content, token) =>
    http.patch("/api/customer/" + url, content, setHeaders(token)),
};

export default Api;
