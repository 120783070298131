import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../Store/Actions/SignInActions";
import { signInReducer } from "../../../Store/Reducers/SignInReducer";

export const HomeAnalytics = ({ analytics, setAnalytics }) => {
  const dispatch = useDispatch();
  const companyStats = useSelector(
    (state) => state.signInReducer.data?.company_stats
  );
  useEffect(() => {
    dispatch(getUserData());
  }, []);

  return (
    <div className="home__analytics">
      <div className="home__analytics-block">
        <div className="home__analytic-items">
          <div className="home__analytic-item">
            <div className="item_icon">
              <img src="../assets/icons/spent.svg" alt="" />
              <span>Spent</span>
            </div>
            <div className="item_data">
              {companyStats?.spent ? `$${companyStats.spent}` : "$0"}
            </div>
            <div className="item_progress-bar"></div>
          </div>
          <div className="home__analytic-item">
            <div className="item_icon">
              <img src="../assets/icons/total-users.svg" alt="" />
              <span>Total Users</span>
            </div>
            <div className="item_data">
              {companyStats?.total_users ? companyStats.total_users : "0"}
            </div>
            <div className="item_progress-bar"></div>
          </div>
        </div>
        <div className="home__analytic-items">
          <div className="home__analytic-item">
            <div className="item_icon">
              <img src="../assets/icons/active-users.svg" alt="" />
              <span>Active Users</span>
            </div>
            <div className="item_data">
              {companyStats?.active_users ? companyStats.active_users : "0"}
            </div>
            <div className="item_progress-bar"></div>
          </div>
          <div className="home__analytic-item">
            <div className="item_icon">
              <img src="../assets/icons/total-visit.svg" alt="" />
              <span>Total visits</span>
            </div>
            <div className="item_data">
              {companyStats?.total_visits ? companyStats?.total_visits : "0"}
            </div>
            <div className="item_progress-bar"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
