import { GET_USER_DATA, LOGIN } from "../Types/SignInType";
import { loaderHide, loaderShow } from "./LoaderActions";
import { showToast } from "./ToastActions";
import Api from "../../API/api";
import { AUTH_LOGIN, GET_PROFILE } from "../../API/endpoints";

export const login = (data, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${AUTH_LOGIN}`, data)
    .then((res) => {
      dispatch(loaderHide());
      dispatch(loginSuccess(res.data.token));
      localStorage.setItem("userToken", res.data.token);
      dispatch(
        showToast({
          title: "Success",
          text: "Welcome to Wazoo",
          type: "success",
        })
      );
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Login",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
};

export const getUserData = (data) => (dispatch) => {
  dispatch(loaderShow());

  Api.getData(`${GET_PROFILE}`, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(getUserDataSuccess(res.data));
    })
    .catch((err) => {
      if (err.response.status == 307) {
        window.location.href = "/signup/details";
      }
      dispatch(loaderHide());
    });
};

export const loginSuccess = (token) => ({
  type: LOGIN,
  payload: token,
});

export const getUserDataSuccess = (data) => ({
  type: GET_USER_DATA,
  payload: data,
});
