export const ERRORS = {
  email: "Email is not correct",
  old_password: "The current password is incorrect",
  password:
    "Upper-case, lower-case, numbers and special characters, at least 8 characters",
  repeat_password: "Your repeat password is not correct",
  required: "This field is required",
};
export const CHART_DATA_1 = [
  {
    name: "Jan",
    value: "50",
  },
  {
    name: "Feb",
    value: "321",
  },
  {
    name: "Mar",
    value: "333",
  },
  {
    name: "Apr",
    value: "444",
  },
  {
    name: "May",
    value: "512",
  },
  {
    name: "Jun",
    value: "322",
  },
  {
    name: "Jul",
    value: "432",
  },
  {
    name: "Aug",
    value: "122",
  },
  {
    name: "Sept",
    value: "602",
  },
  {
    name: "Oct",
    value: "203",
  },
  {
    name: "Nov",
    value: "505",
  },
  {
    name: "Dec",
    value: "100",
  },
];

export const CHART_DATA_2 = [
  {
    name: "Jan",
    value: "520",
  },
  {
    name: "Feb",
    value: "31",
  },
  {
    name: "Mar",
    value: "133",
  },
  {
    name: "Apr",
    value: "244",
  },
  {
    name: "May",
    value: "712",
  },
  {
    name: "Jun",
    value: "122",
  },
  {
    name: "Jul",
    value: "232",
  },
  {
    name: "Aug",
    value: "322",
  },
  {
    name: "Sept",
    value: "202",
  },
  {
    name: "Oct",
    value: "103",
  },
  {
    name: "Nov",
    value: "305",
  },
  {
    name: "Dec",
    value: "100",
  },
];

export const SELECT_TIME_DATA = [
  { value: "year", label: "Year" },
  { value: "month", label: "Month" },
  { value: "week", label: "Week" },
];
