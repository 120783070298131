import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Input } from "../../../UI/Input";
import { validationEmail } from "../../../Global/Validation";
import { getEmail } from "../../../Store/Actions/SignUpActions";
import { AppleButton } from "../../../UI/AppleButton";
import { GoogleButton } from "../../../UI/GoogleButton";
import { Button } from "../../../UI/Button";
import { ERRORS } from "../../../Const/const";

export const EmailSignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorEmail, setErrorEmail] = useState({
    status: false,
    text: ERRORS.email,
  });
  const [successEmail, setSuccessEmail] = useState(false);
  const [value, setValue] = useState("");
  const signupEmail = useRef(null);

  useEffect(() => {
    signupEmail.current.focus();
  }, []);

  useEffect(() => {
    if (validationEmail(value)) {
      setSuccessEmail(true);
      setErrorEmail((old) => ({ ...old, status: false }));
    } else {
      setSuccessEmail(false);
    }
  }, [value]);

  const getValue = (target) => {
    setValue(target.value.replace(/\s/g, ""));
  };
  const toNextScreen = () => {
    history.push("/signup/code", { email: value.trim() });
  };

  const submitHandler = () => {
    if (value !== "" && validationEmail(value)) {
      setErrorEmail((old) => ({ ...old, status: false }));
      dispatch(getEmail(value.trim(), toNextScreen));
    } else {
      setErrorEmail((old) => ({ ...old, status: true }));
    }
  };

  const clearInput = () => {
    setErrorEmail((old) => ({ ...old, status: false }));
    setValue("");
  };

  return (
    <section className={`signup__email`}>
      <h1 className={"signup__title"}>Sign up</h1>
      {/*<p className="signup__description">Sign up with Open account</p>*/}
      {/*<div className="signup__buttons">*/}
      {/*  <div className="signup__google">*/}
      {/*    <GoogleButton />*/}
      {/*  </div>*/}
      {/*  <div className="signup__apple">*/}
      {/*    <AppleButton />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="signup__line"></div>*/}
      <p className="signup__description">With email address</p>
      <form
        className={"signup__field"}
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <Input
          clear={clearInput}
          error={errorEmail.status}
          errorText={errorEmail.text}
          success={successEmail}
          value={value}
          icon={"/assets/icons/email.svg"}
          getValue={getValue}
          placeholder={"Your email"}
          refs={signupEmail}
        />
        <div className="signup__email-button">
          <Button text={"Continue"} type={"success"} />
        </div>
      </form>
    </section>
  );
};
