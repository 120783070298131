import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { SignUp } from "./Screens/SignUp";
import { SignIn } from "./Screens/SignIn";
import { ForgotPassword } from "./Screens/ForgotPassword";
import PrivateRoute from "./Route/PrivatRoute";
import { Home } from "./Screens/Home";
import { Settings } from "./Screens/Settings";
import "./Scss/main.scss";
import { Navigation } from "./Components/Navigation";
import { useSelector } from "react-redux";
import { Loader } from "./Components/Loader";

import "react-toastify/dist/ReactToastify.css";
import { Help } from "./Screens/Help";
import { AddUser } from "./Components/Home/AddUser";
import { getUserData } from "./Store/Actions/SignInActions";
import { useDispatch } from "react-redux";
import { MyExpenses } from "./Screens/MyExpenses";
import { UserDetails } from "./Screens/UserDetails";
import { Stripe } from "./Components/SignUp/Stripe";
import { UsageHistory } from "./Screens/UsageHistory";

export const App = () => {
  const dispatch = useDispatch();
  const toastData = useSelector((state) => state.toastReducer);
  const location = useLocation();
  const [navigation, setNavigation] = useState(true);
  const loader = useSelector((state) => state.loaderReducer.loader);
  const addUserPopup = useSelector((state) => state.usersReducer.showModal);
  const history = useHistory();
  const userData = useSelector((state) => state.signInReducer.data);
  useEffect(() => {
    if (
      location.pathname == "/login" ||
      location.pathname.match(/\/signup/g) ||
      location.pathname.match(/\/forgot-password/g)
    ) {
      setNavigation(false);
    } else {
      const token = localStorage.getItem("userToken");
      if (!token) return history.push("/login");
      setNavigation(true);
    }
  }, [location.pathname]);
  useEffect(() => {
    notify();
  }, [toastData]);

  useEffect(() => {
    if (userData?.billing?.last_4 === "**** **** **** ****") {
      history.push("/signup/subscription");
    }
  }, [userData]);
  const notify = () => {
    if (toastData.show) {
      return toast[toastData.type](<Msg />, toastData.settings);
    }
  };

  const Msg = ({ closeToast }) => (
    <div>
      <h2>{toastData.title}</h2>
      <p>{toastData.text}</p>
    </div>
  );

  // setTimeout(() => {
  //   console.clear();
  // }, 500);

  return (
    <div className={`${navigation ? "main" : ""}`}>
      {navigation && <Navigation key={navigation} />}
      {loader && <Loader />}
      {toastData.show && <ToastContainer />}
      {addUserPopup && <AddUser />}
      <Switch>
        <Route path={"/signup"} component={SignUp} />
        <Route exact path={"/login"} component={SignIn} />
        <Route path={"/forgot-password"} component={ForgotPassword} />
        <PrivateRoute exact path={"/"} component={Home} />
        <PrivateRoute path={"/settings"} component={Settings} />
        <PrivateRoute path={"/expenses"} component={MyExpenses} />
        <PrivateRoute path={"/Help"} component={Help} />
        <PrivateRoute path={"/user-details"} component={UserDetails} />
        <PrivateRoute exact path="/card" component={Stripe} />
        <PrivateRoute exact path="/usage-history" component={UsageHistory} />
      </Switch>
    </div>
  );
};
