export const BASE_URL = "https://api.wazooapp.com";
export const AUTH_LOGIN = "auth/login";
export const SIGN_UP_EMAIL = "auth/register";
export const SIGN_UP_VERIFY = "auth/verify";
export const PASSWORD = "auth/password";
export const REGISTER_PASSWORD = "auth/set-password";
export const FORGOT_EMAIL = "auth/reset-password";
export const CHANGE_PASSWORD = "account/change-password";
export const USERS_GET = "staff/index";
export const ADD_USER = "staff/add";
export const UPDATE_USER = "staff/update";
export const DELETE_USER = "staff/delete";
export const CHANGE_COMPANY = "account/update-name";
export const GET_PROFILE = "account/index";
export const GET_EXPORT = "staff/export"
export const IMPORT_FILE = "staff/import"
export const GET_FAQ = "app/initialize";
export const HISTORY_USER = "staff/history";

