import { showToast } from "./ToastActions";
import { loaderHide, loaderShow } from "./LoaderActions";
import Api from "../../API/api";
import { CHANGE_PASSWORD, CHANGE_COMPANY } from "../../API/endpoints";

export const changeFullName = (full_name) => (dispatch) => {
  let data = JSON.parse(localStorage.getItem("currentUser"));
  let users = JSON.parse(localStorage.getItem("users"));
  data.full_name = full_name;
  users.map((item) => {
    if (item.id == data.id) {
      item.full_name = full_name;
    }
    return item;
  });
  localStorage.setItem("currentUser", JSON.stringify(data));
  localStorage.setItem("users", JSON.stringify(users));
  dispatch(
    showToast({ title: "Success", text: "Full name changed", type: "success" })
  );
};

export const changeCompanyName = (data) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${CHANGE_COMPANY}`, data, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Success",
          text: res.data.message,
          type: "success",
        })
      );
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
  // let data = JSON.parse(localStorage.getItem("currentUser"));
  // let users = JSON.parse(localStorage.getItem("users"));
  // data.company_name = company_name;
  // users.map((item) => {
  //   if (item.id == data.id) {
  //     item.company_name = company_name;
  //   }
  //   return item;
  // });
  // localStorage.setItem("currentUser", JSON.stringify(data));
  // localStorage.setItem("users", JSON.stringify(users));
  // dispatch(
  //   showToast({
  //     title: "Success",
  //     text: "Company name changed",
  //     type: "success",
  //   })
  // );
};
export const changeEmail = (email) => (dispatch) => {
  let data = JSON.parse(localStorage.getItem("currentUser"));
  let users = JSON.parse(localStorage.getItem("users"));
  data.email = email;
  users.map((item) => {
    if (item.id == data.id) {
      item.email = email;
    }
    return item;
  });
  localStorage.setItem("currentUser", JSON.stringify(data));
  localStorage.setItem("users", JSON.stringify(users));
  dispatch(
    showToast({ title: "Success", text: "Email changed", type: "success" })
  );
};

export const changePassword = (data, validPas) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${CHANGE_PASSWORD}`, data, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Success",
          text: res.data.message,
          type: "success",
        })
      );
    })
    .catch((err) => {
      dispatch(loaderHide());
      validPas()
      dispatch(
        showToast({
          title: "Error",
          text: err.data.message,
          type: "error",
        })
      );
    });
};
