import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import Api from "../../../API/api";
import { loaderHide, loaderShow } from "../../../Store/Actions/LoaderActions";

export const MyExpensesTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginateNumber, setPaginateNumber] = useState(2);

  const checkMediaQuery = () => {
    if (window.innerWidth < 500) {
      setPaginateNumber(1);
    } else {
      setPaginateNumber(2);
    }
  };
  window.addEventListener("resize", checkMediaQuery);
  window.addEventListener("load", checkMediaQuery);
  useEffect(() => {
    getData();
  }, [currentPage]);

  const getData = () => {
    dispatch(loaderShow());
    Api.getData(
      `account/expenses?page=${currentPage}&perPage=10`,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        setData(res.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };

  return (
    <div className="expenses__table">
      <div className="expenses__table-header">
        <div className="expenses__table-th date">Code</div>
        <div className="expenses__table-th type">
          <span className="text">Type</span>
        </div>

        <div className="expenses__table-th data">
          <span className="text">Description</span>
        </div>

        <div className="expenses__table-th data">
          <span className="text">Date</span>
        </div>

        <div className="expenses__table-th amount">
          <span className="text">Amount</span>
        </div>
      </div>

      <div className="expenses__table-content">
        {data?.list?.map((item, index) => (
          <div className="item" key={index}>
            <div className="expenses__table-hr"></div>
            <div className="expenses__table-row">
              <div className="subtitle-mobile address">
                <span>Code</span>
                <div className="expenses__table-td address">{item?.code}</div>
              </div>
              <div className="subtitle-mobile type">
                <span>Type</span>
                <div className="expenses__table-td type">
                  {item?.type == "subscription" ? (
                    <>
                      <img src="../assets/icons/subscription.svg" alt="" />
                      <p>Subscription</p>
                    </>
                  ) : (
                    <>
                      <img src="../assets/icons/toilet-icon-usage.svg" alt="" />
                      <p>Usage</p>
                    </>
                  )}
                </div>
              </div>
              <div className="subtitle-mobile data">
                <span>Description</span>
                <div className="expenses__table-td data">
                  {item?.description ? item?.description : "-"}
                </div>
              </div>
              <div className="subtitle-mobile data">
                <span>Date</span>
                <div className="expenses__table-td data">{item?.date}</div>
              </div>
              <div className="subtitle-mobile amount">
                <span>Amount</span>
                <div className="expenses__table-td amount">
                  {item?.amount_formatted ? item?.amount_formatted : "$0"}
                </div>
              </div>
            </div>
            <div className="expenses__table-hr"></div>
          </div>
        ))}
        {data?.totalPage > 1 && (
          <div className="paginate">
            <ReactPaginate
              key={currentPage}
              breakLabel="..."
              nextLabel="Next"
              pageClassName="page"
              activeClassName="active"
              pageRangeDisplayed={paginateNumber}
              marginPagesDisplayed={paginateNumber}
              onPageChange={(value) => {
                setCurrentPage(value?.selected + 1);
              }}
              initialPage={+currentPage - 1}
              pageCount={data?.totalPage}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </div>
  );
};
