import {
	GET_CODE,
	GET_EMAIL,
	GET_PASSWORD,
} from "../Types/ForgotTypes";

const initialState = {
	email: null,
	password: null,
	code: null,
};

export default function forgotReducer(state = initialState, action) {
	switch (action.type) {
		case GET_EMAIL:
			return {
				...state,
				email: action.payload,
			};
		case GET_CODE:
			return {
				...state,
				code: action.payload,
			};
		case GET_PASSWORD:
			return {
				...state,
				password: action.payload,
			};
		default:
			return state;
	}
}
