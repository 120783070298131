import { loaderHide, loaderShow } from "./LoaderActions";
import Api from "../../API/api";
import {GET_EXPORT, HISTORY_USER, IMPORT_FILE} from "../../API/endpoints";
import { showToast } from "./ToastActions";
import { GET_USER } from "../Types/StaffTypes";
import {getUsers} from "./UsersAction";

export const exportStaff = () => (dispatch) => {
  dispatch(loaderShow());
  Api.getData(`${GET_EXPORT}`, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      // window.open(res.data.download_link, '_blank')
      let ifrm = document.createElement("iframe");
      ifrm.setAttribute("src", res.data.download_link);
      ifrm.style.width = "1px";
      ifrm.style.height = "1px";
      document.body.appendChild(ifrm);
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
};

export const importStaff = (file) => (dispatch) => {
  dispatch(loaderShow());
  const body = new FormData();
  body.append("file", file);
  Api.postData(`${IMPORT_FILE}`, body, localStorage.getItem("userToken"))
    .then((res) => {
      dispatch(loaderHide());
      dispatch(getUsers());
      dispatch(
        showToast({
          title: "Success",
          text: "File imported!",
          type: "success",
        })
      );
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message,
          type: "error",
        })
      );
    });
};

export const getUser = (id) => (dispatch) => {
      dispatch(loaderShow());
      Api.getData(
        `${HISTORY_USER}?user_id=${id}`, localStorage.getItem("userToken")
      )
        .then((res) => {
          dispatch(loaderHide());
          dispatch(getUserSuccess(res.data.data));
        })
        .catch((err) => {
          dispatch(loaderHide());
        });
      };


export const getUserSuccess = (payload) => ({
  type: GET_USER,
  payload
});
