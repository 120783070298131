import React, { useEffect, useRef, useState } from "react";
import PinField from "react-pin-field";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Button } from "../../../UI/Button";

import { getCode } from "../../../Store/Actions/SignUpActions";
import { useSelector } from "react-redux";

export const VerifySignUp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [codeStatus, setCodeStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const codeFiled = useRef(null);

  useEffect(() => {
    codeFiled?.current?.inputs[0]?.focus();
  }, []);

  useEffect(() => {
    if (codeStatus && code?.length < 6) {
      setCodeStatus(false);
      setLoader(false);
    }
  }, [code]);

  const codeHandler = (value) => {
    setCode(value);
    if (value?.length == 6) {
      setDisabledBtn(false);
    }
  };

  const codeAction = (statusCode) => {
    if (statusCode == 200) {
      history.push("/signup/password");
    } else {
      setCodeStatus(true);
      setDisabledBtn(true);
    }
  };

  const submitHandler = () => {
    codeFiled?.current?.inputs[5]?.blur();
    dispatch(
      getCode(
        {
          verify_code: code,
          verify_token: localStorage.getItem("register_data_verify_token"),
          contact: location.state.email,
        },
        codeAction
      )
    );
    setDisabledBtn(true);
  };
  return (
    <section className="signup__verify">
      <Link to={"/signup"} style={{textDecoration: 'none'}}>
        <div className="back-button">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.79289 20.2071C10.1834 20.5976 10.8166 20.5976 11.2071 20.2071C11.5976 19.8166 11.5976 19.1834 11.2071 18.7929L9.79289 20.2071ZM3 12L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071L3 12ZM11.2071 5.20711C11.5976 4.81658 11.5976 4.18342 11.2071 3.79289C10.8166 3.40237 10.1834 3.40237 9.79289 3.79289L11.2071 5.20711ZM11.2071 18.7929L3.70711 11.2929L2.29289 12.7071L9.79289 20.2071L11.2071 18.7929ZM3.70711 12.7071L11.2071 5.20711L9.79289 3.79289L2.29289 11.2929L3.70711 12.7071Z" fill="#000000"/>
          <path d="M21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L21 13ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13L3 11ZM21 11L3 11L3 13L21 13L21 11Z" fill="#000000"/>
          </svg>
          <span>Back</span>
        </div>
      </Link>
      <h1 className={"signup__title"}>Sign up</h1>
      <p className="signup__description">
      We just sent you a verification code. Check your{" "}
        <span style={{ color: "#5856D6" }}>{location.state.email}</span> inbox
        to get them
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <div className={`signup__pin ${codeStatus ? "error" : ""}`}>
          <PinField
            ref={codeFiled}
            type={"text"}
            inputmode={"text"}
            validate={/^[0-9]+$/}
            format={(key) => key}
            length={6}
            onChange={(value) => codeHandler(value)}
          />
        </div>
        {codeStatus && (
          <p className="signup__pin-error">
            The code you entered is incorrect.
          </p>
        )}
        <div className="signup__email-button">
          <Button
            loader={loader}
            disabled={disabledBtn}
            text={"Continue"}
            type={"success"}
          />
        </div>
      </form>
    </section>
  );
};
