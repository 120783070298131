import React from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { ForgotEmail } from "../../Components/ForgotPassword/Email";
import { ForgotVerify } from "../../Components/ForgotPassword/Verify";
import {
  ForgotPasswordChange,
  ForgotPasswordCreate,
} from "../../Components/ForgotPassword/Password";

export const ForgotPassword = () => {
  return (
    <section className={`forgot`}>
      <Helmet>
        <title>Forgot Password | Wazoo</title>
      </Helmet>
      <nav className="forgot__navigation">
        <div className="left">
          <div className="logo">
            <img src="/assets/icons/logo.svg" alt="Wazoo" />
          </div>
          <div className="mobile-logo">
            <img src="/assets/icons/mobile_logo.svg" alt="Wazoo" />
          </div>
        </div>
        <div className="right">
          <span>
            Already a member?<Link to={"/login"}>Sign in</Link>
          </span>
        </div>
      </nav>
      <div className="forgot__content">
        <div className="forgot__left">
          <img src="/assets/images/signup_sidebar.svg" alt="" />
        </div>
        <div className="forgot__right">
          <Switch>
            <Route exact path="/forgot-password" component={ForgotEmail} />
            <Route
              exact
              path="/forgot-password/code"
              component={ForgotVerify}
            />
            <Route
              exact
              path="/forgot-password/password"
              component={ForgotPasswordCreate}
            />
          </Switch>
        </div>
      </div>
    </section>
  );
};
