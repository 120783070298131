import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import Api from "../../API/api";
import { showToast } from "../../Store/Actions/ToastActions";
import { loaderHide, loaderShow } from "../../Store/Actions/LoaderActions";
import { useDispatch } from "react-redux";

export const Textarea = () => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const getValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value?.length > 0) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [value]);

  const handleSubmit = () => {
    dispatch(loaderShow());
    Api.postData(
      "support/submit",
      { message: value },
      localStorage.getItem("userToken")
    )
      .then(() => {
        dispatch(loaderHide());
        setValue("");
        dispatch(
          showToast({
            title: "Success",
            text: "Thanks for feedback",
            type: "success",
          })
        );
        setValue("");
      })
      .catch((e) => {
        dispatch(loaderHide());
      });
  };

  return (
    <div className="textarea">
      <div className="textarea__subtitle">
        <span>
          Have any questions or feedback? Please send us a message below
        </span>
      </div>
      <form
        className="textarea__body"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <textarea
          value={value}
          onChange={(e) => {
            getValue(e);
          }}
          className="input"
          placeholder="Please enter your message"
        />
        <div className="button">
          <Button disabled={disableBtn} type="success" text="Send" />
        </div>
      </form>
    </div>
  );
};
