export const selectStyle = {
  option: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    background: state.isSelected ? "#DEEBFF" : "transparent",
    color: "#172B4D",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 10,
    paddingBottom: 10,
    position: "relative",
    "&:hover": {
      background: state.isSelected ? "#DEEBFF" : "#F4F5F7",
    },
    "&::after": {
      // content: "",
      position: "absolute",
      width: 2,
      background: "red",
      top: 0,
      left: 0,
    },
  }),
  control: (styles, state) => ({
    ...styles,
    borderRadius: 12,
    outline: "none",
    cursor: "pointer",
    border: state.isFocused ? "1px solid #e4e5e9;" : "1px solid #e4e5e9;",
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? "1px solid #e4e5e9;" : "1px solid #e4e5e9;",
    },
    paddingLeft: 16,
  }),
  input: (styles, state) => ({
    ...styles,
    outline: "none",
  }),
  menu: (styles, state) => ({
    ...styles,
    marginTop: 10,
    boxShadow:
      "0px 8px 5px rgba(23, 43, 77, 0.04), 0px 15px 12px rgba(23, 43, 77, 0.08)",
    borderRadius: 8,
    paddingTop: 12,
    paddingBottom: 12,
    width: 210,
    right: 0,
  }),
};
