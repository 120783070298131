import React, { useEffect, useState } from "react";
import { NavLink, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../Store/Actions/SignInActions";
import { getUsers, showAddModal } from "../../Store/Actions/UsersAction";
import { exportStaff, importStaff } from "../../Store/Actions/StaffActions";
import { HomeChar } from "../../Components/MyExpenses/Char";
import {
  CHART_DATA_1,
  CHART_DATA_2,
  SELECT_TIME_DATA,
} from "../../Const/const";
import Select from "react-select";
import { MyExpensesTable } from "../../Components/MyExpenses/Table";
import { selectStyle } from "../../Const/selectStyle";
import { loaderHide, loaderShow } from "../../Store/Actions/LoaderActions";
import Api from "../../API/api";

export const MyExpenses = () => {
  const history = useHistory();
  const [analytics, setAnalytics] = useState(true);
  const [chartFilter, setChartFilter] = useState("week");
  const users = useSelector((state) => state.usersReducer.data);
  const [usageBalance, setUsageBalance] = useState(null);

  useEffect(() => {
    dispatch(getUsers());
  }, []);
  // const getExportLink = () => {
  //   dispatch(exportStaff());
  // };
  // const importFile = (e) => {
  //   dispatch(importStaff(e.target.files[0]));
  // };
  useEffect(() => {
    history.push("/expenses");
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
    getData();
  }, []);

  const getData = () => {
    dispatch(loaderShow());
    Api.getData(
      `account/expenses?page=${1}&perPage=10`,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        console.log(res.data);
        dispatch(loaderHide());
        setUsageBalance(res.data?.usage_balance);
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };
  return (
    <section className={"expenses"}>
      <Helmet>
        <title>My Expenses | Wazoo</title>
      </Helmet>
      <div className="main__header">
        <h1 className="title">My expenses</h1>
      </div>
      <div className="expenses__content">
        <div className="white-box">
          <div className="expenses__content-header">
            <div className="expenses__subtitle">
              <span className="text">Total expenses</span>
              <div className="usage-balance">
                <span>
                  <img src="/assets/icons/usage-balance.svg" alt="balance" />
                </span>
                <span className="key">Usage Balance</span>
                <span className="value">
                  $ {usageBalance ? usageBalance : 0}
                </span>
              </div>
            </div>
            <div className="expenses__time-select">
              <Select
                options={SELECT_TIME_DATA}
                defaultValue={SELECT_TIME_DATA[2]}
                isSearchable={false}
                isClearable={false}
                styles={selectStyle}
                onChange={(value) => setChartFilter(value.value)}
              />
            </div>
          </div>
          <HomeChar
            key={chartFilter}
            chartFilter={chartFilter}
            data={analytics ? CHART_DATA_1 : CHART_DATA_2}
          />
        </div>
        <div className="white-box">
          <div className="expenses__content-header">
            <div className="expenses__subtitle">Last Transactions</div>
            {/* <div className="expenses__time-select">
              <Select
                options={SELECT_TIME_DATA}
                defaultValue={SELECT_TIME_DATA[0]}
                isSearchable={false}
                isClearable={false}
                styles={selectStyle}
              />
            </div> */}
          </div>
          <MyExpensesTable />
        </div>
      </div>
    </section>
  );
};
