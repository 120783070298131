import React, { useEffect, useState } from "react";

export const Accordion = ({title, desc}) => {
	const [accOpen, setAccOpen] = useState(false)

	return (
		<div className="accordion">
			<div className="accordion__item">
				<div onClick={() => setAccOpen(!accOpen)}
				     className="accordion__title" >
					<span>{title}</span>
					<div className={`accordion__icon ${accOpen ? 'accordion__icon_active' : ''}`}>
						<img src="/assets/icons/faq-arrow.svg" alt=""/>
					</div>
				</div>
				{ accOpen && (
					<div className="accordion__content" dangerouslySetInnerHTML={{__html: desc}} />
				)}
			</div>
			<div className="accordion__hr"></div>
		</div>

	);
};
