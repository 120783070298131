import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Accordion } from "../../../UI/Accordion";
import { Textarea } from "../../../UI/Textarea";
import {getFaq} from "../../../Store/Actions/FeedbackActions";
import {useDispatch, useSelector} from "react-redux";

export const Faq = () => {
  const faqList = useSelector((state => state.faqReducer.data))

  const renderFAQ = () => {
    return faqList.map((item, id) => (
      <Accordion key={id} title={item.question} desc={item.answer} />
    ));
  };

  return (
    <section className={"faq"}>
      <Helmet>
        <title> Help & Feedback | Wazoo</title>
      </Helmet>
      <h2 className="faq__subtitle">FAQ</h2>
      {renderFAQ()}
      <h2 className="feedback__subtitle">Feedback</h2>
      <Textarea />
    </section>
  );
};
