export const validationEmail = (value) => {
  if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/)) {
    return true;
  } else {
    return false;
  }
};

export const validationPassword = (value) => {
  if (
    value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}[!@#$%^&*]$/)
  ) {
    return true;
  } else {
    return false;
  }
};
