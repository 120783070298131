import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import Api from "../../../API/api";
import { useDispatch } from "react-redux";
import { loaderHide, loaderShow } from "../../../Store/Actions/LoaderActions";
import { showToast } from "../../../Store/Actions/ToastActions";
import { useSelector } from "react-redux";

const CheckoutForm = (clientSecret) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.signInReducer.data);
  const CARD_OPTIONS = {
    labels: "floating",
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#162E3C",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSmoothing: "antialiased",
        backgroundColor: "#e7e7e7",
        padding: "5px 10px 5px 20px",
        paddingLeft: 10,
        marginLeft: 10,
        left: 2,
        lineHeight: "50px",
        borderRadius: 10,
        fontWeight: 500,
        fontSize: 14,
        color: "#1A1D1F",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
          text: "Card",
        },
      },
      input: {
        backgroundColor: "#e7e7e7",
        lineHeight: 16,
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "red",
      },
      icon: {
        left: 15,
        paddingLeft: 15,
      },
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(loaderShow());

    if (elements == null) {
      return;
    }
    // history.push("/");
    await stripe
      .confirmCardSetup(clientSecret.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userData?.name_full,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          dispatch(
            showToast({
              title: "Error",
              text: result.error.message,
              type: "error",
            })
          );
          dispatch(loaderHide());
          // Inform the customer that there was an error.
        } else {
          dispatch(loaderHide());
          Api.postData(
            `account/update-payment-method`,
            {
              payment_method: result.setupIntent.payment_method,
            },
            localStorage.getItem("userToken")
          )
            .then((res) => {
              dispatch(loaderHide());
              dispatch(
                showToast({
                  title: "Success",
                  text: res.data.message,
                  type: "success",
                })
              );
              history.push("/");
            })
            .catch((err) => {
              dispatch(loaderHide());
            });
        }
      });
  };
  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="FormRow">
        <div className={"substrate-icon"}></div>
        <CardElement style={{ marginTop: 100 }} options={CARD_OPTIONS} />
      </div>
      <button
        className={`SubmitButton`}
        type="submit"
        disabled={!stripe || !elements}
      >
        Continue
      </button>
    </form>
  );
};

const stripePromise = loadStripe(
  "pk_live_51LLC3OBjqZaxTwk09G9mOf3EyHvBUMi8QZsJymKlzbAEhqu1AAmGCew8491Jf0ovyUZzyetAvgMWQYQCYXQSk5YR00UGrBTbOL"
);
export const Stripe = ({ color = "white" }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clientSecret == null) {
      dispatch(loaderShow());
      Api.postData(`account/get-secret`, {}, localStorage.getItem("userToken"))
        .then((res) => {
          dispatch(loaderHide());
          setClientSecret(res.data.secret);
        })
        .catch((error) => {
          dispatch(loaderHide());
        });
    }
  }, []);

  if (!clientSecret) {
    return <h1>Wait</h1>;
  } else {
    return (
      <Elements stripe={stripePromise}>
        <section className="signup__subscription">
          <h1 className={"payment-info__title"}>Payment info</h1>
          {clientSecret && <CheckoutForm clientSecret={clientSecret} />}
        </section>
      </Elements>
    );
  }
};
