import React, { useEffect, useState } from "react";

import { Input } from "../../../UI/Input";
import ReactTooltip from "react-tooltip";
import { Button } from "../../../UI/Button";
import {
  addUser,
  hideAddModal,
  updateUser,
} from "../../../Store/Actions/UsersAction";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../Store/Actions/ToastActions";

import "./index.scss";

export const AddUser = () => {
  const dispatch = useDispatch();
  const editedUsers = useSelector((state) => state.usersReducer.editedUser);
  const [updateLimit, setUpdateLimit] = useState(false);

  const [data, setData] = useState({
    full_name: {
      value: "",
      error: false,
      errorText: "Full name must contain at least 3 characters",
    },
    phone_number: {
      value: "",
      error: false,
      errorText: "Incorrect phone number",
    },
  });

  useEffect(() => {
    if (data.full_name.value?.length >= 3) {
      setData((old) => ({
        ...old,
        full_name: { ...old.full_name, error: false },
      }));
    }
  }, [data.full_name.value, data.phone_number.value]);

  const formattedPhone = (value) => {
    let replacedInput = value?.replace(/\+1/g, "")
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let phoneFormatted = !replacedInput[2]
      ? replacedInput[1]
      : "(" +
      replacedInput[1] +
      ") " +
      replacedInput[2] +
      (replacedInput[3] ? "-" + replacedInput[3] : "");

    return phoneFormatted
  }
  useEffect(() => {
    if (editedUsers) {
      setData((old) => ({
        ...old,
        full_name: { ...old.full_name, value: editedUsers.name },
        phone_number: {
          ...old.phone_number,
          value: formattedPhone(editedUsers?.phone)?.replace(/\+1/g, ""),
        },
      }));
    }
  }, [editedUsers]);



  const getValue = (target) => {
    if (target.id === "phone_number") {
      setData((old) => ({
        ...old,
        [target.id]: { ...old[target.id], value: formattedPhone(target.value) },
      }));
    } else {
      setData((old) => ({
        ...old,
        [target.id]: { ...old[target.id], value: target.value },
      }));
    }
  };

  const validateName = () => {
    if (data.full_name.value?.length < 3) {
      setData((old) => ({
        ...old,
        full_name: { ...old.full_name, error: true },
      }));
      return false;
    } else {
      return true;
    }
  };

  const validateNumber = () => {
    if (data.phone_number.value?.length !== 14) {
      setData((old) => ({
        ...old,
        phone_number: { ...old.phone_number, error: true },
      }));
      return false;
    } else {
      return true;
    }
  };

  const add = () => {
    const name = validateName();
    const phone = validateNumber();
    if (name && phone) {
      dispatch(
        addUser({
          name: data.full_name.value,
          phone_number: data.phone_number.value
            ? "+1" + data.phone_number.value
            : "",
        })
      );
    }
  };

  const update = () => {
    if (editedUsers) {
      const name = validateName();
      const phone = validateNumber();
      if (name && phone) {
        dispatch(
          updateUser({
            name: data.full_name.value,
            phone_number: `+1 ${data.phone_number.value}`,
            uid: editedUsers.uid,
          })
        );
      }
    }
  };

  const handleSubmit = () => {
    const body = {};
    body.full_name = data.full_name;
    body.phone_number = data.phone_number;
    if (data.full_name && data.phone_number) {
      if (editedUsers) {
        update();
      } else {
        add(data);
      }
    } else {
      dispatch(
        showToast({
          title: "Error",
          text: "Please enter all fields",
          type: "error",
        })
      );
    }
  };

  const clearInput = (id) => {
    setData((old) => ({
      ...old,
      [id]: { ...old[id], error: false, value: "" },
    }));
    // dispatch(hideAddModal())
  };

  const closePopup = () => {
    dispatch(hideAddModal());
  };

  const UpdateLimitTexts = () => (
    <div className="userPopup__text">
      <p>For each active user you pay $9.99 user / month</p>
      <p>You will be charged at the end of each week for using the toilet.</p>
      <div className="userPopup__text-hr"></div>
      <div className="userPopup__checkbox">
        <div className="checkbox">
          <input id="all" type="checkbox" />
          <label htmlFor="all"></label>
        </div>
        <span>Don’t show again</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="userPopup">
        <div className="userPopup__content">
          <div className="userPopup__header">
            <div className="userPopup__header-left">
              <div className="userPopup__header-block"></div>
              <p className="userPopup__header-title">
                {editedUsers ? "Update user" : "Add a new user"}
              </p>
            </div>
            <div onClick={closePopup} className="userPopup__header-right">
              <span>
                <img src="/assets/icons/close.svg" alt="close" />
              </span>
            </div>
          </div>
          {updateLimit ? (
            <UpdateLimitTexts />
          ) : (
            <>
              <div className="userPopup__field">
                <div className="userPopup__label">
                  <span>Full name</span>
                  <span data-tip data-for="name"></span>
                </div>
                <Input
                  id="full_name"
                  placeholder="Full name"
                  value={data.full_name.value}
                  getValue={getValue}
                  focusInput={true}
                  error={data.full_name.error}
                  clear={() => clearInput("full_name")}
                  errorText={data.full_name.errorText}
                  maxLength={35}
                />
              </div>
              <div className="userPopup__field">
                <div className="userPopup__label">
                  <span>Phone Number</span>
                  <span data-tip data-for="name"></span>
                </div>
                <span
                  className={`number ${
                    data.phone_number.value ? "number_black" : ""
                  } ${data.phone_number.error ? "number_black-error" : ""}`}
                >
                  +1
                </span>
                <Input
                  id="phone_number"
                  placeholder="(000) 000-0000"
                  value={data.phone_number.value}
                  getValue={getValue}
                  focusInput={true}
                  phone={true}
                  error={data.phone_number.error}
                  clear={() => clearInput("phone_number")}
                  errorText={data.phone_number.errorText}
                />
              </div>
            </>
          )}
          <div className="userPopup__btns">
            <div className="userPopup__btn">
              <Button action={closePopup} text={"Cancel"} type={"light"} />
            </div>
            <div className="userPopup__btn">
              {updateLimit ? (
                <Button action={closePopup} text={"Update"} type={"purple"} />
              ) : (
                <Button
                  action={handleSubmit}
                  text={editedUsers ? "Save" : "Add"}
                  type={editedUsers ? "success" : "purple"}
                />
              )}
            </div>
          </div>
        </div>
        <div onClick={closePopup} className="userPopup__bg"></div>
      </div>
    </>
  );
};
