import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ERRORS } from "../../../Const/const";
import { getNames } from "../../../Store/Actions/SignUpActions";

import { Button } from "../../../UI/Button";
import { Input } from "../../../UI/Input";

export const DetailsSignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const detailsRef = useRef(null);
  const [data, setData] = useState({
    company_name: {
      value: "",
      error: false,
      success: false,
      text: ERRORS.required,
    },
    full_name: {
      value: "",
      error: false,
      success: false,
      text: ERRORS.required,
    },
  });

  useEffect(() => {
    detailsRef.current.focus();
  }, []);

  const detailsAction = () => {
    history.push("/signup/subscription");
  };

  const getValue = (target) => {
    setData((old) => ({
      ...old,
      [target.id]: { ...old[target.id], value: target.value },
    }));
  };

  const clearValue = (target) => {
    setData((old) => ({
      ...old,
      [target]: {
        ...old[target],
        value: target.value,
        success: false,
        error: false,
      },
    }));
  };

  const localValidation = (target) => {
    if (data[target].value?.length > 0) {
      setData((old) => ({
        ...old,
        [target]: { ...old[target], success: true, error: false },
      }));
      return true;
    } else {
      setData((old) => ({
        ...old,
        [target]: { ...old[target], success: false, error: true },
      }));
      return false;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const companyNameValid = localValidation("company_name");
    const fullNameValid = localValidation("full_name");
    if (companyNameValid && fullNameValid) {
      detailsAction();
      dispatch(
        getNames(
          {
            company_name: data.company_name.value,
            name_full: data.full_name.value,
          },
          detailsAction
        )
      );
    }
  };

  return (
    <section className="signup__details">
      <h1 className="signup__title">The last step</h1>
      <p className="signup__description">
        Some information little bit about you
      </p>
      <form
        action="#"
        onSubmit={submitHandler}
        className="signup__details-form"
      >
        <div className="signup__details-button">
          <div className="signup__details-input">
            <Input
              value={data.company_name.value}
              icon="/assets/icons/building.svg"
              placeholder="Enter company name"
              error={data.company_name.error}
              success={data.company_name.success}
              errorText={data.company_name.text}
              id="company_name"
              getValue={getValue}
              clear={clearValue}
              refs={detailsRef}
              maxLength={"50"}
            />
          </div>
          <div className="signup__details-input">
            <Input
              value={data.full_name.value}
              icon="/assets/icons/user-check.svg"
              placeholder="Your full name"
              error={data.full_name.error}
              success={data.full_name.success}
              errorText={data.full_name.text}
              id="full_name"
              getValue={getValue}
              clear={clearValue}
              maxLength={"50"}
            />
          </div>
          <div className="signup__details-button">
            <Button type="success" text="Continue" />
          </div>
        </div>
      </form>
    </section>
  );
};
