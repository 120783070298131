import { loaderHide, loaderShow } from "./LoaderActions";
import Api from "../../API/api";
import {GET_FAQ} from "../../API/endpoints";

export const getFaq = () => (dispatch) => {
    dispatch(loaderShow());
    Api.getData(`${GET_FAQ}`, localStorage.getItem("userToken"))
        .then((res) => {
            dispatch(loaderHide());
            dispatch(getFaqSuccess(res.data.faq_list));
        })
        .catch((err) => {
            dispatch(loaderHide());
        });
};

export const getFaqSuccess = (payload) => ({
    type: "GET_FAQ_SUCCESS",
    payload: payload,
})





