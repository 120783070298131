import { combineReducers } from "redux";
import signUpReducer from "./Reducers/SignUpReducer";
import { loaderReducer } from "./Reducers/LoaderReducer";
import { signInReducer } from "./Reducers/SignInReducer";
import { toiletReducer } from "./Reducers/ToiletReducer";
import { toastReducer } from "./Reducers/ToastReducer";
import forgotReducer from "./Reducers/ForgotReducer";
import { usersReducer } from "./Reducers/UsersReducer";
import { faqReducer } from "./Reducers/FeedbackReducer";
import { staffReducer } from "./Reducers/StaffReducer";

const rootReducer = combineReducers({
  signUpReducer,
  loaderReducer,
  signInReducer,
  toiletReducer,
  toastReducer,
  forgotReducer,
  usersReducer,
  faqReducer,
  staffReducer,
});

export default rootReducer;
