import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";

import { Input } from "../../../UI/Input";
import { Button } from "../../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { validationEmail } from "../../../Global/Validation";
import {
  changeCompanyName,
  changeEmail,
  changeFullName,
} from "../../../Store/Actions/SettingsActions";
import { getUserData } from "../../../Store/Actions/SignInActions";
import { showToast } from "../../../Store/Actions/ToastActions";
import { loaderHide, loaderShow } from "../../../Store/Actions/LoaderActions";
import Api from "../../../API/api";

export const Account = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.signInReducer.data);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    dispatch(getUserData());
  }, []);
  const [error, setError] = useState({
    name_full: { status: false, text: "min length 3" },
    company_name: { status: false, text: "min length 3", success: false },
    email: false,
  });
  const [data, setData] = useState({});

  useEffect(() => {
    setData(userData);
  }, [userData]);
  const getValue = (target) => {
    if (target.id == "email") {
      if (validationEmail(target.value)) {
        setData((old) => ({ ...old, [target.id]: target.value }));
        setError((old) => ({ ...old, email: false }));
      } else {
        setError((old) => ({ ...old, email: true }));
      }
    } else {
      setData((old) => ({ ...old, [target.id]: target.value }));
    }
  };
  const fullNameHandler = () => {
    dispatch(changeFullName(data.full_name));
  };
  const emailHandler = () => {
    dispatch(changeEmail(data.email));
  };
  const companyNameHandler = () => {
    dispatch(changeCompanyName(data.company_name));
  };
  const saveData = async () => {
    if (data.company_name == "" || data.company_name.length < 3) {
      return dispatch(
        showToast({
          title: "Error",
          text: "Please enter correct company name (at least 3 characters)",
          type: "error",
        })
      );
    }
    if (data.name_full == "" || data.name_full.length < 3) {
      return dispatch(
        showToast({
          title: "Error",
          text: "Please enter correct full name (at least 3 characters)",
          type: "error",
        })
      );
    }
    dispatch(
      changeCompanyName({
        company_name: data.company_name,
        name_full: data.name_full,
      })
    );
  };

  const addImage = (pathPreview, dataPreview) => {
    dispatch(loaderShow());
    const formData = new FormData();
    formData.append("profile", dataPreview);
    Api.postData(
      `account/upload-profile-picture`,
      formData,
      localStorage.getItem("userToken"),
      true
    )
      .then((res) => {
        dispatch(getUserData());
        dispatch(
          showToast({
            title: "Success",
            text: "Photo uploaded!",
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Error",
            text: err.response.data.errors?.profile[0] ?? "Error",
            type: "error",
          })
        );
      });
  };

  const removeImage = () => {
    dispatch(loaderShow());
    Api.postData(
      `account/remove-profile-picture`,
      {},
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        dispatch(getUserData());
        dispatch(
          showToast({
            title: "Success",
            text: "Photo removed!",
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(loaderHide());
        dispatch(
          showToast({
            title: "Error",
            text: err.response.data.message ?? "Error",
            type: "error",
          })
        );
      });
  };
  return (
    <section className={"profile"}>
      <Helmet>
        <title>Profile | Wazoo</title>
      </Helmet>
      <h2 className="settings__subtitle">Profile information</h2>

      <form
        action="#"
        className="account__form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="account__photo">
          <label htmlFor="upload-photo" className="photo">
            <img
              src={
                data?.company_profile_image
                  ? data?.company_profile_image
                  : `/assets/icons/placeholder-photo.svg`
              }
              alt="user_photo"
            />
          </label>
          <div className="upload__photo">
            <label htmlFor="upload-photo" className="upload__button">
              Upload your logo
            </label>
            <input
              accept="image/x-png,image/jpeg"
              type="file"
              id="upload-photo"
              onChange={(e) => {
                addImage(
                  URL?.createObjectURL(e.target.files[0]),
                  e.target.files[0]
                );
              }}
            />
          </div>
          <div
            onClick={() => {
              removeImage();
            }}
            className="remove__photo"
          >
            Remove
          </div>
        </div>
        <div className="account__field">
          <div className="label">
            <div>
              <span>Full name</span>
              <span data-tip data-for="name">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                    fill="#9A9FA5"
                  />
                </svg>
              </span>
              <ReactTooltip id="name" effect="solid">
                <span>Please enter your first and last name</span>
              </ReactTooltip>
            </div>
          </div>
          <div className="account__input">
            <Input
              id="name_full"
              placeholder="Full name"
              value={data?.name_full}
              getValue={getValue}
              maxLength={"50"}
            />
          </div>
        </div>
        <div className="account__field">
          <div className="label">
            <div>
              <span>Company name</span>
              <span data-tip data-for="company_name">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                    fill="#9A9FA5"
                  />
                </svg>
              </span>
              <ReactTooltip id="company_name" effect="solid">
                <span>
                  Please enter your company name. At least 3 characters.
                </span>
              </ReactTooltip>
            </div>
          </div>
          <div className="profile__input">
            <Input
              id="company_name"
              placeholder="Company name"
              error={error.company_name.status}
              errorText={error.company_name.text}
              success={error.company_name.success}
              value={data?.company_name}
              getValue={getValue}
              maxLength={"50"}
            />
          </div>
        </div>
        <div className="account__field">
          <div className="label">
            <div>
              <span>Email</span>
              <span data-tip data-for="email">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6668 6.99992C13.6668 10.6818 10.6821 13.6666 7.00016 13.6666C3.31826 13.6666 0.333496 10.6818 0.333496 6.99992C0.333496 3.31802 3.31826 0.333252 7.00016 0.333252C10.6821 0.333252 13.6668 3.31802 13.6668 6.99992ZM7.00016 6.33325C7.36835 6.33325 7.66683 6.63173 7.66683 6.99992V10.3338C7.66683 10.702 7.36835 11.0005 7.00016 11.0005C6.63197 11.0005 6.3335 10.702 6.3335 10.3338V6.99992C6.3335 6.63173 6.63197 6.33325 7.00016 6.33325ZM7.00016 4.99992C7.36835 4.99992 7.66683 4.70144 7.66683 4.33325C7.66683 3.96506 7.36835 3.66659 7.00016 3.66659C6.63197 3.66659 6.3335 3.96506 6.3335 4.33325C6.3335 4.70144 6.63197 4.99992 7.00016 4.99992Z"
                    fill="#9A9FA5"
                  />
                </svg>
              </span>
              <ReactTooltip id="email" effect="solid">
                <span>Please enter your email</span>
              </ReactTooltip>
            </div>
            {/*<div className="change-email">Change email</div>*/}
          </div>
          <div className="account__input">
            <Input
              id="email"
              placeholder="Email"
              error={error.email}
              value={data?.email}
              getValue={getValue}
              clearNot={true}
              readOnly={true}
              maxLength={"50"}
            />
          </div>
        </div>
        <div className="account__button">
          <Button action={saveData} text="Save" type="success" />
        </div>
      </form>
    </section>
  );
};
