import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Faq } from "../../Components/Help/Faq";
import {useDispatch, useSelector} from "react-redux";

import { getUserData } from "../../Store/Actions/SignInActions";
import {getFaq} from "../../Store/Actions/FeedbackActions";
import {faqReducer} from "../../Store/Reducers/FeedbackReducer";

export const Help = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserData());
    dispatch(getFaq())
  }, []);

    return (
    <section className={"help"}>
      <Helmet>
        <title>Help & Feedback | Wazoo</title>
      </Helmet>
      <h1 className={`title`}>Help & Feedback</h1>
      <div className="help__main ">
        <div className="help__content">
          <Switch>
            <Route exact path={"/help"} component={Faq} />
          </Switch>
        </div>
      </div>
    </section>
  );
};
