import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { SELECT_TIME_DATA } from "../../Const/const";
import { selectStyle } from "../../Global/SelectStyles";
import Api from "../../API/api";
import { useDispatch } from "react-redux";
import { loaderHide, loaderShow } from "../../Store/Actions/LoaderActions";

export const UsageHistory = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  useEffect(() => {
    getData();
  }, []);
  const getData = (page = 1) => {
    dispatch(loaderShow());
    Api.getData(
      `account/use-history?page=${page}&perPage=10`,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        setData(res.data.list);
        setTotalPage(res.data.totalPage);
        console.log(res.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };

  const handlePageClick = (page) => {
    getData(page.selected + 1);
  };

  return (
    <section className="usage-history">
      <Helmet>
        <title>Usage History | Wazoo</title>
      </Helmet>
      <h1 className="title">My expenses</h1>
      <div className="usage-history__content">
        <div className="white-box">
          <div className="home__header">
            <div className="home__subtitle">Use history</div>
            {/* <div className="home__time-select">
              <Select
                options={SELECT_TIME_DATA}
                defaultValue={SELECT_TIME_DATA[1]}
                isSearchable={false}
                isClearable={false}
                styles={selectStyle}
                onChange={(value) => {
                  // dispatch(getUserData(value.value));
                  //   setChartFilter(value.value);
                }}
              />
            </div> */}
          </div>
          <div className="usage-history__table">
            <div className="usage-history__th">
              <div className="name">Name</div>
              <div className="type">Address</div>
              <div className="date">Date</div>
              <div className="time">Time</div>
            </div>
            {data?.map((item, index) => (
              <div className="usage-history__td">
                <div className="name">{item?.user?.name_full}</div>
                <div className="type">{item?.toilet?.location_label}</div>
                <div className="date">{item?.date}</div>
                <div className="time">{item?.time}</div>
              </div>
            ))}
          </div>
          {totalPage > 1 && (
            <div className="paginate">
              <ReactPaginate
                pageCount={totalPage}
                breakLabel="..."
                nextLabel="Next"
                pageClassName="page"
                activeClassName="active"
                onPageChange={handlePageClick}
                previousLabel="Prev"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
