import React, { useEffect } from "react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Account } from "../../Components/Settings/Account";
import { Payment } from "../../Components/Settings/Payment";
import { Security } from "../../Components/Settings/Security";
import { useDispatch } from "react-redux";
import { getUserData } from "../../Store/Actions/SignInActions";
import { showToast } from "../../Store/Actions/ToastActions";

export const Settings = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname == "/settings") {
      history.push("/settings/account");
    }
  }, [location.pathname]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  return (
    <section className={"settings"}>
      <Helmet>
        <title>Settings | Wazoo</title>
      </Helmet>
      <h1 className={`title`}>Settings</h1>
      <div className="settings__main white-box">
        <div className="settings__nav">
          <NavLink
            className="settings__nav-link"
            to="/settings/account"
            activeClassName="active"
          >
            Account
          </NavLink>
          <NavLink
            className="settings__nav-link"
            to="/settings/security"
            activeClassName="active"
          >
            Security
          </NavLink>
          <NavLink
            className="settings__nav-link"
            to="/settings/payment"
            activeClassName="active"
          >
            Payment
          </NavLink>
        </div>
        <div className="settings__content">
          <Switch>
            <Route exact path={"/settings/account"} component={Account} />
            <Route exact path={"/settings/payment"} component={Payment} />
            <Route exact path={"/settings/security"} component={Security} />
          </Switch>
        </div>
      </div>
      <div className={"other"}>
        <h2 className="other__subtitle">Other</h2>
        <div className="other__body">
          <Link to={"/help"} className={"other__link"}>
            <span className={"icon"}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  fill="#6F767E"
                />
                <circle cx="12" cy="18" r="1" fill="#6F767E" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 8C11.1307 8 10.3886 8.5551 10.1135 9.33325C9.92948 9.85396 9.35815 10.1269 8.83744 9.94284C8.31672 9.75879 8.0438 9.18747 8.22784 8.66675C8.77648 7.11451 10.2568 6 12 6C14.2091 6 16 7.79086 16 10C16 11.8638 14.7252 13.4299 13 13.874V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V13C11 12.4477 11.4477 12 12 12C13.1045 12 14 11.1046 14 10C14 8.89543 13.1045 8 12 8Z"
                  fill="#6F767E"
                />
              </svg>
            </span>
            <span className={"text"}>Help & Feedback</span>
          </Link>
          <div
            className={"other__logout"}
            onClick={() => {
              localStorage.removeItem("userToken");
              dispatch(
                showToast({
                  title: "Success",
                  text: "Log out success",
                  type: "success",
                })
              );
              history.push("/login");
            }}
          >
            <span className={"icon"}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7071 7.79289C17.3166 7.40237 16.6834 7.40237 16.2929 7.79289C15.9024 8.18342 15.9024 8.81658 16.2929 9.20711L18.0858 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H18.0858L16.2929 14.7929C15.9024 15.1834 15.9024 15.8166 16.2929 16.2071C16.6834 16.5976 17.3166 16.5976 17.7071 16.2071L20.5 13.4142C21.2811 12.6332 21.281 11.3668 20.5 10.5858L17.7071 7.79289Z"
                  fill="#6F767E"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 5C3 3.34315 4.34315 2 6 2H12C13.6569 2 15 3.34315 15 5V6C15 6.55228 14.5523 7 14 7C13.4477 7 13 6.55228 13 6V5C13 4.44772 12.5523 4 12 4H6C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H12C12.5523 20 13 19.5523 13 19V18C13 17.4477 13.4477 17 14 17C14.5523 17 15 17.4477 15 18V19C15 20.6569 13.6569 22 12 22H6C4.34315 22 3 20.6569 3 19V5Z"
                  fill="#6F767E"
                />
              </svg>
            </span>
            <span className={"text"}>Log out</span>
          </div>
        </div>
      </div>
    </section>
  );
};
