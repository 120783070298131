import { GET_CODE, GET_EMAIL, GET_PASSWORD } from "../Types/ForgotTypes";

import { loaderHide, loaderShow } from "./LoaderActions";
import { showToast } from "./ToastActions";
import Api from "../../API/api";
import {
  FORGOT_EMAIL,
  REGISTER_PASSWORD,
  SIGN_UP_VERIFY,
} from "../../API/endpoints";

export const getEmail = (email, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${FORGOT_EMAIL}`, { email })
    .then((res) => {
      dispatch(loaderHide());
      dispatch(emailSuccess(res.data.verify_token));
      localStorage.setItem("forgot_data_verify_token", res.data.verify_token);
      dispatch(
        showToast({
          title: "Success",
          text: "Please check your email",
          type: "success",
        })
      );
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message ?? "Email is not correct",
          type: "error",
        })
      );
    });
};

export const emailSuccess = (email) => ({
  type: GET_EMAIL,
  payload: email,
});

export const getCode = (data, callback) => (dispatch) => {
  dispatch(loaderShow());
  Api.postData(`${SIGN_UP_VERIFY}`, data)
    .then((res) => {
      dispatch(loaderHide());
      localStorage.setItem("forgot_data_member_uid", res.data.member_uid);
      localStorage.setItem(
        "forgot_data_password_token",
        res.data.password_token
      );
      dispatch(
        codeSuccess({
          member_uid: res.data.member_uid,
          password_token: res.data.password_token,
        })
      );
      callback(res.status);
    })
    .catch((err) => {
      dispatch(loaderHide());
      dispatch(
        showToast({
          title: "Error",
          text: err.response.data.message ?? "Code is not correct",
          type: "error",
        })
      );
    });
};

export const codeSuccess = (code) => ({
  type: GET_CODE,
  payload: code,
});

export const getPassword = (form, callback) => (dispatch) => {
  dispatch(loaderShow());
  const data = {
    member_uid: form.member_uid,
    password_token: form.password_token,
    password: form.password,
  };
  Api.postData(`${REGISTER_PASSWORD}`, data)
    .then((res) => {
      dispatch(loaderHide());
      // dispatch(namesSuccess(data?.full_name));
      localStorage.setItem("userToken", res.data.token);
      localStorage.removeItem("forgot_data_password");
      localStorage.removeItem("forgot_data_member_uid");
      localStorage.removeItem("forgot_data_verify_token");
      localStorage.removeItem("forgot_data_password_token");
      dispatch(
        showToast({
          title: "Success",
          text: "Sign up complete, please login",
          type: "success",
        })
      );
      callback();
    })
    .catch((err) => {
      dispatch(loaderHide());
    });
};

export const passwordSuccess = (password) => ({
  type: GET_PASSWORD,
  payload: password,
});
