import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";

import { Input } from "../../../UI/Input";
import { Button } from "../../../UI/Button";
import { useDispatch, useSelector } from "react-redux";
import { validationEmail } from "../../../Global/Validation";
import {
  changeCompanyName,
  changeEmail,
  changeFullName,
} from "../../../Store/Actions/SettingsActions";
import { getUserData } from "../../../Store/Actions/SignInActions";
import { Link, useHistory } from "react-router-dom";
import Api from "../../../API/api";
import { loaderHide, loaderShow } from "../../../Store/Actions/LoaderActions";

export const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    dispatch(loaderShow());
    Api.getData(`account/index`, localStorage.getItem("userToken"))
      .then((res) => {
        dispatch(loaderHide());
        setData(res.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };
  return (
    <section className={"payment"}>
      <Helmet>
        <title>Payment | Wazoo</title>
      </Helmet>
      <h2 className="settings__subtitle">Payment info</h2>
      {data?.billing?.exp_date ? (
        <div className="payment__box">
          <div className="subtitle">Cardholder:</div>
          <div className="payment__body">
            <div>
              <div className="card">{data?.billing?.last_4}</div>
            </div>
            <div>
              <div className="subscription">
                Wazoo subscription (
                <Link to="/">{data?.billing?.total_users} employees</Link>)
              </div>
              <div className="billing-date">
                Next billing date: {data?.billing?.billing_date}
              </div>
            </div>
            <div
              className="payment__delete"
              onClick={() => {
                history.push("/card");
              }}
            >
              <span>Update</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="payment__delete"
          style={{
            maxWidth: 180,
            marginTop: 24,
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => {
            history.push("/card");
          }}
        >
          <span>Add Card</span>
        </div>
      )}
    </section>
  );
};
