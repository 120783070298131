import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Api from "../../../API/api";
import { loaderHide, loaderShow } from "../../../Store/Actions/LoaderActions";

export const HomeChar = ({ data, chartFilter }) => {
  const dispatch = useDispatch();
  const [chart, setChart] = useState(null);
  useEffect(() => {
    getData();
  }, []);
  const content = (state) => {
    if (!state?.payload) {
      return <div className="graph-content"></div>;
    }
    return (
      <div className="graph-content">
        <p className="graph-label">{state?.payload[0]?.payload?.name}</p>
        <p className="graph-value">{`$${state?.payload[0]?.payload.value}`}</p>
      </div>
    );
  };

  const getData = () => {
    dispatch(loaderShow());
    Api.getData(
      `account/chart?filter=${chartFilter}`,
      localStorage.getItem("userToken")
    )
      .then((res) => {
        dispatch(loaderHide());
        setChart(res.data);
      })
      .catch((err) => {
        dispatch(loaderHide());
      });
  };
  return (
    <div className="home__char">
      <ResponsiveContainer width="100%" height={270}>
        <LineChart data={chart}>
          <XAxis tickMargin={15} dataKey="name" />
          <YAxis tickMargin={20} dataKey="value" />
          <Tooltip
            content={content}
            labelStyle={{
              fontSize: 12,
              fontWeight: "500",
              color: "#EFEFEF",
              padding: 0,
            }}
            contentStyle={{
              background: "#272B30",
              borderWidth: 1,
              borderColor: "rgba(255, 255, 255, 0.12)",
              borderRadius: "8px",
            }}
            itemStyle={{
              color: "#FCFCFC",
              fontSize: 12,
              fontWeight: "600",
            }}
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#5856D6"
            strokeWidth={4}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
