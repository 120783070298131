import {
  GET_CODE,
  GET_EMAIL,
  GET_NAMES,
  GET_PASSWORD,
} from "../Types/SignUpTypes";

const initialState = {
  email: null,
  password: null,
  code: null,
  company_name: null,
  full_name: null,
};

export default function signUpReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case GET_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case GET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case GET_NAMES:
      return {
        ...state,
        company_name: action.payload.company_name,
        full_name: action.payload.full_name,
      };
    default:
      return state;
  }
}
