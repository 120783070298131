import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../../UI/Input";
import { Button } from "../../../UI/Button";
import { validationEmail } from "../../../Global/Validation";
import { getEmail } from "../../../Store/Actions/ForgotActions";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ERRORS } from "../../../Const/const";

export const ForgotEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorEmail, setErrorEmail] = useState({
    status: false,
    text: ERRORS.email,
  });
  const [successEmail, setSuccessEmail] = useState(false);
  const [value, setValue] = useState("");
  const forgotEmail = useRef(null);

  useEffect(() => {
    forgotEmail.current.focus();
  }, []);

  useEffect(() => {
    if (validationEmail(value)) {
      setSuccessEmail(true);
      setErrorEmail((old) => ({ ...old, status: false }));
    } else {
      setSuccessEmail(false);
    }
  }, [value]);

  const getValue = (target) => {
    setValue(target.value.replace(/\s/g, ""));
  };
  const toNextScreen = () => {
    history.push("/forgot-password/code", { email: value.trim() });
  };

  const submitForgotHandler = () => {
    if (value !== "" && validationEmail(value)) {
      setErrorEmail((old) => ({ ...old, status: false }));
      dispatch(getEmail(value.trim(), toNextScreen));
    } else {
      setErrorEmail((old) => ({ ...old, status: true }));
    }
  };

  const clearInput = () => {
    setErrorEmail((old) => ({ ...old, status: false }));
    setValue("");
  };

  return (
    <section className={`forgot__email`}>
      <Link to={"/login"} style={{ textDecoration: "none" }}>
        <div className="back-button">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.79289 20.2071C10.1834 20.5976 10.8166 20.5976 11.2071 20.2071C11.5976 19.8166 11.5976 19.1834 11.2071 18.7929L9.79289 20.2071ZM3 12L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071L3 12ZM11.2071 5.20711C11.5976 4.81658 11.5976 4.18342 11.2071 3.79289C10.8166 3.40237 10.1834 3.40237 9.79289 3.79289L11.2071 5.20711ZM11.2071 18.7929L3.70711 11.2929L2.29289 12.7071L9.79289 20.2071L11.2071 18.7929ZM3.70711 12.7071L11.2071 5.20711L9.79289 3.79289L2.29289 11.2929L3.70711 12.7071Z"
              fill="#000000"
            />
            <path
              d="M21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L21 13ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13L3 11ZM21 11L3 11L3 13L21 13L21 11Z"
              fill="#000000"
            />
          </svg>
          <span>Back</span>
        </div>
      </Link>
      <h1 className={"forgot__title"}>Forgot password</h1>
      <p className="forgot__description">
        We will send a message within a code
      </p>
      <form
        className="forgot__form"
        onSubmit={(e) => {
          e.preventDefault();
          submitForgotHandler();
        }}
      >
        <Input
          clear={clearInput}
          error={errorEmail.status}
          errorText={errorEmail.text}
          success={successEmail}
          value={value}
          icon={"/assets/icons/email.svg"}
          getValue={getValue}
          placeholder={"Your email"}
          refs={forgotEmail}
        />
        <div className="forgot__email-button">
          <Button text={"Continue"} type={"success"} />
        </div>
      </form>
    </section>
  );
};
