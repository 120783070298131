import {
  GET_USERS,
  HIDE_MODAL,
  SET_CURRENT_PAGE,
  SHOW_MODAL,
} from "../Types/UsersTypes";

const initialState = {
  data: [],
  totalPage: 0,
  currentPage: 1,
  showModal: false,
  editedUser: null,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        data: action.payload.list,
        totalPage: action.payload.totalPage,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SHOW_MODAL:
      document.querySelector("html").style.overflow = "hidden";
      return {
        ...state,
        showModal: true,
        editedUser: action.payload,
      };
    case HIDE_MODAL:
      document.querySelector("html").style.overflow = "visible";
      return {
        ...state,
        showModal: false,
      };

    default:
      return state;
  }
};
